<template>
  <!--  顶部-->
  <div class="snav">
    <div>
      <router-link to="/">
        <img :src="`/api/v1/qingqiu/staticResource/iconImg/logo.png`" style="height: 28px;" alt=""/>
      </router-link>
      <router-link to="/">
        <i class="el-icon-arrow-left"
           style="position: relative; top: 3px; color: #fff; font-weight: bold; font-size: 18px; padding-left: 20px;"></i>
      </router-link>
      <span style="position: relative;top: 3px;">
      <i @click="toggleSideBar" v-if="sidebar.opened" class="el-icon-more toggleSidebar" style=""></i>
      <i @click="toggleSideBar" v-else class="el-icon-more-outline toggleSidebar" style=""></i>
      <!-- <lang-select
        style="width: 18px; font-size: 1.5em;margin-left: 20px;color: #fff;"
        class="set-language"
      /> -->
    </span>
      <!--    元器件/模块电路-->
      <div class="libcategory">
      <span v-for="(item,index) in modelList" :key="index"
            @click="selectLib(item)"
            :class="activeModel==item?'active':''"
      >
        {{ item }}
      </span>
      </div>
    </div>
    <div style="display: flex;justify-content: flex-start;align-items: center;">
      <div style="position: relative;font-size: 20px;color: #FFFFFF;margin-right: 10px;" @mouseenter="show=true" @mouseleave="show=false">
        <i class="el-icon-bell" style="width: 20px;cursor: pointer;"></i>
        <div class="message-num" v-if="unReaderNum > 0">{{unReaderNum}}</div>
        <transition name="el-zoom-in-top">
          <div v-show="show&&unReaderNum > 0" style="position: absolute;top: 40px;left: -100px;z-index: 10000;">
            <div class="message-list">
              <div class="item" v-for="(it,i) in msgList" :key="i" @click="lookDetail(it)">
                <div class="left"><i class="el-icon-bell" style="width: 20px"></i></div>
                <div class="right">
                  <div class="top">
                    <div class="t-left">{{ it.msgTittle }}</div>
                    <div class="t-right">[{{ $t("navbar.process.processMessage") }}]</div>
                  </div>
                  <div class="time">{{ it.createTime }}</div>
                </div>
              </div>
              <div @click="showMore" style="width: 100%;padding-top:10px;height:30px;text-align: center;color: #333;font-size: 13px;cursor: pointer;">
                {{ $t("navbar.process.viewMore") }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <span style="margin-left: 10px;" class="download-client-btn" @click="handleUploadClient">{{ $t("navbar.uploadClient") }}</span>
      <span style="margin-left: 10px;" class="download-client-btn" @click="handleDownloadClient">{{ $t("navbar.downloadClient") }}</span>
      <div>
        <div class="pull-left">
          <img style="height:28px;margin-right: 5px;" src="/api/v1/qingqiu/staticResource/iconImg/user.png" alt=""
               srcset="">
        </div>
        <div class="pull-left" style="line-height: 28px;margin-right: 20px;color:#fff;">
          {{ userDetail.userName }}
        </div>
      </div>
      <div>
        <el-dropdown @command="scomand" :hide-on-click="false" style="display: table-cell;vertical-align:middle">
        <span class="el-dropdown-link">
          <i class="icon icon-align-justify" style="font-size:22px"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">{{ $t("navbar.editPassword") }}</el-dropdown-item>
            <el-dropdown-item command="2">{{ $t("navbar.logOut") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <reset-pwd ref="resetPwdRef"></reset-pwd>
    <!-- 上传客户端 -->
    <el-dialog
        :title="$t('navbar.modal.uploadClientModal.title')"
        :visible.sync="UPdialogFormVisible"
        :modal="false"
        width="35%"
        :before-close="closeUP"
    >
      <el-form :model="upFrom" :rules="rules1" ref="UPdown" label-width="130px">
        <el-form-item :label="$t('navbar.modal.uploadClientModal.content.form.clientProgram')" prop="app">
          <el-upload
              drag
              action=""
              :on-remove="handleRemove"
              :on-change="getChange"
              :auto-upload="false"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="fileList"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              {{ $t('navbar.modal.uploadClientModal.tips.fileTips') }}
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('navbar.modal.uploadClientModal.content.form.productName')" prop="name">
          <el-input autocomplete="off" v-model="upFrom.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeUP">{{ $t('navbar.modal.uploadClientModal.footerBtn.cancel') }}</el-button>
        <el-button type="primary" :loading="loading" @click="submitUpload">{{ $t('navbar.modal.uploadClientModal.footerBtn.confirm') }}</el-button>
      </div>
    </el-dialog>
    <!-- 下载客户端 -->
    <el-dialog
        :title="$t('navbar.modal.downloadClientModal.title')"
        :visible.sync="DonwdialogFormVisible"
        :modal="false"
        width="35%"
        center
    >
      <el-form label-width="170px" style="text-align: center">
        <el-form-item :label="$t('navbar.modal.downloadClientModal.content.downloadFileSelection')" style="display: inline-block">
          <el-select :loading="loading" v-model="versions" :placeholder="$t('navbar.modal.downloadClientModal.content.placeholder')" clearable>
            <el-option
                v-for="item in versionsList"
                :key="item.id_"
                :label="item.product + ' - ' + item.filename"
                :value="item.id_"
            >
              <span style="float: left">{{ item.product + ' - ' + item.filename}}</span>
              <el-button v-if="btnRole.deleteClient" style="float: right;padding-left:10px" type="text" @click.stop="delversion(item.id_)">{{$t('navbar.modal.downloadClientModal.content.fileOptionsDel')}}</el-button>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button
            type="primary"
            @click="down"
            style="width: 200px; height: 40px"
        >{{$t('navbar.modal.downloadClientModal.footerBtn.download')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {exitApp} from "@/utils/auth";

import {
  downLoadList,
  uploadPC,
  delPC
} from "@/api/login";
import {
  getMessageNum,
  postMessagePage,
} from "@/api/message";
import ResetPwd from "@/components/public/ResetPwd";
import LangSelect from "@/components/LangSelect";

let ws = null;
let timer = null;

export default {
  components: {
    ResetPwd,
    LangSelect
  },
  data() {
    const upLoadRule = (rule, value, callback) => {
      callback();
    };
    return {
      modelList: [],
      unReaderNum:0,
      ws:{},
      show:false,
      msgList:[],
      formLabelWidth: "150px",
      // 上传客户端
      UPdialogFormVisible: false,
      upFrom: {
        name: "",
      },
      fileList: [],
      dataFile: [],
      // 下载客户端
      DonwdialogFormVisible: false,
      versions: "",
      versionsList: [],
      loading:false,
      btnRole: {},
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "activeModel", "permission_addRoutes", "device", "userDetail", "name"]),
    rules1() {
      const upLoadRule = (rule, value, callback) => {
        callback();
      };
      const rules = {
        app: [{ required: true, validator: upLoadRule, trigger: "blur" }],
        name: [{ required: true, message: this.$t('navbar.modal.uploadClientModal.content.formRules.productName'), trigger: "blur" }],
      };
      // 清空表单验证信息
      // this.$nextTick(() => {
      //   this.$refs["UPdown"] && this.$refs["UPdown"].resetFields();
      // });
      return rules;
    },
  },
  watch: {
    "$i18n.locale": function () {
      this.$nextTick(() => {
        this.$refs.UPdown && this.$refs.UPdown.resetFields();
      });
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    scomand(a) {
      if (a == 2) {
        exitApp();
      } else if (a == 1) {
        this.$refs.resetPwdRef.init();
      }
    },
    getUserDetail() {
      this.$store.dispatch("user/GetUserInfo", {}).then(() => {
      });
    },
    //切换 cms/cbb，重新获取侧边栏
    selectLib(item) {
      this.modelActive = item;
      this.$store.dispatch("user/setActiveModel", item).then(() => {
        let ro = this.showRoutes();
        if (ro.length > 0) {
          this.$router.push(ro[0].redirect)
        }
      });
    },
    showRoutes() {
      return this.permission_addRoutes.filter(r => {
        return r.meta.menuFrom == this.activeModel
      })
    },
    initActiveModel() {
      let arr = [];
      this.permission_addRoutes.filter(r => {
        arr.push(r.meta.menuFrom);
      })
      let list = Array.from(new Set(arr));
      this.modelList = list;
      if (!this.activeModel) {
        const {meta} = this.$route
        this.$store.dispatch("user/setActiveModel", meta.menuFrom).then(() => {
        });
      }
    },
    handleUploadClient() {
      this.UPdialogFormVisible = true;
    },
    handleDownloadClient() {
      this.DonwdialogFormVisible = true;
      this.getdownLoadList()
    },
    getdownLoadList() {
      this.loading = true;
      downLoadList()
          .then((res) => {
            this.versionsList = res;
          }).finally(r => {
        this.loading = false;
      })
    },
    // 移除文件
    handleRemove(file, fileList) {
    },
    getChange(file, fileList) {
      //文件改变时
      this.dataFile = fileList;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        this.$t('navbar.modal.uploadClientModal.tips.exceedTips',{ count: files.length + this.dataFile.length })
      );
    },
    submitUpload() {
      this.$refs.UPdown.validate((valid) => {
        if (valid) {
          if (this.dataFile.length == 0) {
            this.$message(this.$t('navbar.modal.uploadClientModal.tips.fileEmptyTips'));
            return;
          }
          this.loading = true;
          let param = new FormData();
          this.dataFile.forEach((v, i) => {
            param.append("file", v.raw);
          });
          param.append("product", this.upFrom.name);
          uploadPC(param)
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.closeAndClear();
            })
            .finally(r => {
              this.loading = false;
            })
        }
      });
    },
    closeUP() {
      this.$refs.UPdown.clearValidate()
      this.upFrom.name = "";
      this.fileList = [];
      this.dataFile = [];
      this.UPdialogFormVisible = false;
      this.loading = false;
    },
    delversion(id) {
      this.$confirm(this.$t('navbar.modal.downloadClientModal.tips.delTipsContent'), this.$t('navbar.modal.downloadClientModal.tips.delTipsTitle'), {
          confirmButtonText: this.$t('navbar.modal.downloadClientModal.tips.delTipsConfirm'),
          cancelButtonText: this.$t('navbar.modal.downloadClientModal.tips.delTipsCancle'),
          type: 'warning'
        }).then(() => {
          delPC(id).then((res) => {
            this.getdownLoadList()
            this.$message({
              type: 'success',
              message: res.msg
            });
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('navbar.modal.downloadClientModal.tips.cancelTips')
          });
        });
    },
    down() {
      if(!this.versions){
        this.$message(this.$t('navbar.modal.downloadClientModal.tips.fileEmptyTips'))
        return
      }
      let path = this.versionsList.find(v => v.id_ == this.versions);
      window.location.href = this.DEEHOWBASEURL + `/api/v1/client/download?product=${path.product}`;
      this.DonwdialogFormVisible = false;
      this.versionsList = [];
      this.versions = "";
    },
    closeAndClear() {
      this.$refs.UPdown.clearValidate()
      this.upFrom.name = "";
      this.fileList = [];
      this.dataFile = [];
      this.UPdialogFormVisible = false;
      this.loading = false;
    },
    lookDetail(item, index) {
      this.$router.push({path: "/MESSAG/innerMessage/messageDetail", query: {id: item.id, status: item.status}});
    },
    showMore() {
      this.$router.push({path: "/MESSAGE/allMessage"});
    },
    getMessage() {
      getMessageNum().then(res => {
        this.unReaderNum = parseInt(res.data);
        let par = {
          num: 1,
          size: 5,
          msgType: '',
          status: '0',
        }
        postMessagePage(par).then(r => {
          this.msgList = r.data;
          if (ws) {
            ws.close();
            ws = null;
          }
          // 开启socket
          this.createSocket();
        })
      })
    },
    // start - - - - - - socket
    createSocket() {
      const userId = JSON.parse(localStorage.getItem('userInfo')).id;
      let w = location.protocol.indexOf("https") > -1 ? "wss" : "ws";
      let h = location.host;
      let socketUrl = `${w}://${h}/notification/${userId}`;
      ws = new WebSocket(socketUrl);
      ws.addEventListener("open",this.openHandle);
      ws.addEventListener("close",this.closeHandle);
      ws.addEventListener("message",this.messageHandle);
      ws.addEventListener("error",this.errorHandle);
    },
    openHandle() {
      console.log("socket连接成功")
    },
    closeHandle() {
      console.log("socket已关闭")
      // 断开重连
      this.restartWs();
    },
    messageHandle(e) {
      console.log("socket接收消息")
      console.log(e)
      this.unReaderNum = parseInt(e.data);
    },
    errorHandle() {
      console.log("socket错误!!!")
    },
    // 断开重连
    restartWs() {
      console.log("socket连接失败,准备重连...")
      timer = setInterval(() => {
        this.createSocket();
        if (ws && ws.readyState === 0) {
          clearInterval(timer);
          timer = null;
        }
      },2000)
    },
    // 手动关闭
    handleClose() {
      if (ws) {
        ws.close();
      }
    },
    // 手动发送方法
    handleSend() {
      if (ws) {
        ws.send("ping");
      }
    },
    // socket - - - - - - end
  },
  mounted() {
    this.btnRole = this.$store.state.myData.ubtn;
    this.getUserDetail();
    this.initActiveModel();
    this.getMessage();
  },
};
</script>

<style lang='scss' scoped>
.message-num{
  position: absolute;
  top: 0;
  right: -5px;
  background: #d70505;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  text-align: center;
}
.message-list{
  width: 260px;
  background-color: #fff;
  box-shadow: 0 0 3px 3px #eee;
  .item{
    color: #0a80ff;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #f5f6f7;
    cursor: pointer;
    &:hover{
      background-color: #f5f6f7;
    }
    .left{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ee6f2d;
      color: #FFFFFF;
      font-size: 20px;
      margin-right: 10px;
    }
    .right{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: #333;
      font-size: 13px;
      .top{
        display: flex;
        justify-content: center;
        align-items: center;
        .t-left{}
        .t-right{
          font-size: 12px;
          color: #999;
          margin-left: 30px;
        }
      }
      .time{
        font-size: 11px;
        margin-top: 5px;
      }
    }
  }
}
.snav {
  height: 44px;
  background: #293c55;
  line-height: 1.1;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggleSidebar {
    font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
    color: #fff;
  }
}

.libcategory {
  color: rgb(202, 199, 199);
  display: inline-block;
  margin-left: 40px;

  span {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }

  .active {
    color: #fff;
    font-size: 15px;
    border-bottom: 2px solid #fff;
    padding-bottom: 3px;
  }
}

.download-client-btn {
  color: rgb(202, 199, 199);
  margin: 0 15px;
  line-height: 28px;
  cursor: pointer;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
</style>
