export default {
  // 登录页
  login: {
    title: '账号登录',
    logIn: '登录',
    account: '账号',
    password: '密码',
    accountTips: '请输入登录账号！',
    passwordTips: '请输入登录密码！'
  },
  // 导航
  navbar: {
    dashboard: '首页',
    logOut: '退出登录',
    editPassword: '修改密码',
    uploadClient: '上传客户端',
    downloadClient: '下载客户端',
    process: {
      processMessage: '流程消息',
      viewMore: '查看更多',
    },
    modal: {
      uploadClientModal: {
        title: '上传客户端',
        content: {
          form: {
            clientProgram: "客户端程序",
            productName: "产品名称"
          },
          formRules: {
            productName: "请输入名称"
          },
        },
        footerBtn: {
          cancel: '取消',
          confirm: '确定',
        },
        tips: {
          fileTips: '将文件拖到此处，或点击上传',
          fileEmptyTips: "请先选择要上传的文件！",
          exceedTips: "当前限制选择 1 个文件，本次选择了 {count} 个文件"
        }
      },
      downloadClientModal: {
        title: '下载客户端',
        content: {
          downloadFileSelection: "下载文件选择",
          placeholder: '请选择',
          fileOptionsDel: "删除"
        },
        footerBtn: {
          download: '下载',
        },
        tips: {
          fileEmptyTips: "请选择下载文件！",
          delTipsTitle: '提示',
          delTipsContent: "此操作将删除该客户端, 是否继续?",
          cancelTips: "已取消删除",
          delTipsCancle: '取消',
          delTipsConfirm: '确定',
        }
      },
      editPasswordModal: {
        title: '个人密码设置',
        content: {
          form: {
            originalPassword: "旧密码",
            newPassword: "新密码",
            confirmPassword: "确认密码",
          },
          formRules: {
            originalPassword: "请输入旧密码",
            newPassword: "请输入新密码",
            confirmPassword1: "请输入确认密码",
            confirmPassword2: "两次密码请保持一致"
          },
        },
        footerBtn: {
          cancel: '取消',
          confirm: '确定',
        },
        tips: {
          fileTips: '将文件拖到此处，或点击上传',
          fileEmptyTips: "请先选择要上传的文件！",
          setPasswordTipsTitle: "系统提示"
        }
      },
    }
  },
  // 菜单
  pages: {
    public: {
      official: "正式",
      temporary: "临时",
      process: "流程中",
      cancel: "取消",
      save: "保存",
      search: '搜索',
      confirm: "确定",
      delete: '删除',
      loading: "拼命加载中...",
      uploadFile: "上传文件",
    },
    // 元器件库
    componentsLib: {
      tree: {
        placeholder: "请输入分类名称",
        open: "展开所有节点",
        close: "折叠所有节点",
        compare: "同类器件对比",
        cateViewNav: "分类视图导航",
        preferredComLib: "优选元器件库",
        partCateNav: "物料分类导航",
        manufacturerNav: "制造商导航",
        domesticSubstitutePool: "国产替代库",
      },
      search: {
        placeholder: "快速搜索，多条件以 | 或 空格 分隔，示例:RC06*L | 10000*",
        please: "请按",
        attr: "属性搜索",
        advSearch: "高级搜索",
        devSearch: "关键器件搜索",
        isAdv: "高级搜索弹窗中，已经输入了检索条件!",
        checkAltium: "校正Altium封装、符号",
        addCbb: "新增模块电路",
        addCms: "新增器件",
        batchOpe: "批量操作",
        batchUpdate: "批量更新",
        batchDel: "批量删除",
        batchImp: "批量导入",
        refreshMainCom: "刷新关键器件属性",
        exportGood: "导出器件",
        exportAll: "导出全部器件",
        exportCate: "导出分类器件",
        exportSel: "导出勾选器件",
        selPartCate: "请先选择物料分类！",
        include: "包含",
        equalTo: "等于",
        noEqualTo: "不等于",
        input: "输入",
        bre: '大于',
        less: '小于',
        breEqu: '大于等于',
        lessEqu: '大于等于',
        addSearch: "添加搜索条件",
        cancel: "取消",
        search: "搜索",
        partNumber: "物料编码",
        partModel: "规格型号",
        close: "关闭",
        confirm: "确定",
        collapseActiveName: "筛选",
        dropFilter: "收起筛选",
        enterOneSearchTerm: "至少输入一项搜索条件",
      },
      tableHeader: {
        operate: "操作",
        moreOperate: "更多操作",
        temOptionalSet: "临时选用设置",
        temOptionalSetSuc: "临时选用设置成功",
        cancelTemOptionalSetSuc: "取消临时选用设置成功",
        ForMaterialSet: "禁用料设置",
        forMaterialSetSuc: "禁用料设置成功",
        cancelForMaterialSet: "取消设置禁用料",
        copyAdd: "复制新增",
        dullMaterialSet: "呆滞料设置",
        dullMaterialSetSuc: "呆滞料设置成功",
        cancelDullMaterialSet: "取消呆滞料设置",
        joinMyChoice: "加入我的自选",
        cancelMyChoice: "取消我的自选",
        searchModuleKey: "搜索关键器件的模块电路",
        partEdit: "修改",
        partDel: "删除",
        partCompare: "对比",
        errTip: "该器件有未修改的错误反馈",
        bevelWorld: "对比框",
        toComp: "去对比",
        clearContrastBar: "清空对比栏",
        canNotCompare: "器件数量小于2，无法比较",
        isDeletePart: "是否确定删除该元器件?",
        tip: "提示",
        addChoiceSuc: "添加自选成功！",
        cancelChoiceSuc: "取消自选成功！",
        selDeletePart: "请勾选要删除的元器件",
        isDeleteDeletePart: "是否确定要删除勾选元器件?",
        selectOneCate: "请选择一个物料分类",
        selectExportPart: "请勾选导出的器件",
        exportSuc: "导出成功",
        exporting: "正在导出器件...",
        exportTip: "您可以直接通过拖拽调节顺序，可勾选需要导出的字段信息，不勾选默认导出全部字段信息",
      },
      fieldConfig: {
        fieldSet: "字段配置",
        filterPlaceholder: "请输入关键字搜索",
        selColumn: "可选择列",
        showColumn: "显示列",
        save: "保存",
        useDefault: "使用默认配置",
        cancel: "取消",
      },
      lifeCycle: {
        time: "时间",
        status: "状态",
      },
      designTraceability: {
        exportDes: "导出设计追溯",
        realPro: "关联产品",
        useTotal: "引用总数",
        proBom: "板卡BOM",
        product: "产品",
        fileName: "设计追溯报表.xlsx"
      },
      history: {
        modVersion: "修改版本",
        modDate: "修改日期",
        modUser: "修改人",
        operate: "操作",
        recover: "恢复",
        previousCom: "与上一版本比较",
        currentCom: "与当前版本比较",
        otherCom: "与指定版本比较",
        select: "请选择",
        confirm: "确定",
        lookAll: "查看全部属性",
        lookSame: "查看相同属性",
        lookNot: "查看不同属性",
        attr: "属性",
        isSame: "是否一致",
        selVer: "请选择比较版本！",
        selVerCom: "请选择比较版本并选中比较数据！",
        confirmRecover: "确认恢复到此版本?",
        tip: "提示",
        cancel: "取消",
        recoverSuc: "已恢复到指定版本",
        canceled: "已取消",
      },
      threeShow: {
        noData: "3D模型文件缺失",
      },
      tableMessageBrand: {
        brand: "品牌",
        brandModel: "品牌型号",
        partCertificationMark: "部品认证标识",
        significantCondition: "有效状态",
        createUser: "创建人",
        createTime: "创建时间",
        updateUser: "更新人",
        updateTime: "更新时间",
      },
      tableMessageBrandSpec: {
        brand: "品牌",
        name: "名称",
        version: "版本",
        status: "状态",
        createTime: "创建时间",
        file: "文件",
      },
      tableMessageBrandBom: {
        coding: "编码",
        status: "状态",
        createTime: "创建时间",
      },
      tableMessageBrandAttach: {
        name: "名称",
        version: "版本",
        status: "状态",
        createUser: "创建人",
        createTime: "创建时间",
        updateTime: "更新时间",
        file: "文件",
      },
      tableMessageBrandAuth: {
        name: "名称",
        version: "版本",
        status: "状态",
        createTime: "创建时间",
        file: "文件",
      },
      fiveUploadFive: {
        selectFile: "选择文件",
        uploadFile: "上传文件",
        selectTip: "请选择文件后点击上传按钮进行文件上传",
        selectTip2: '文件上传后如果有重名文件请处理重名文件的使用状态,如果没有处理则使用默认文件(后面没有"使用此文件"按钮)',
        selectTip3: '下方列表为已上传列表,"(已保存)"状态为已经保存好了的文件',
        fileName: "文件名",
        ope: "操作",
        use: "使用此文件",
        dow: "下载",
        del: "删除",
        none: "暂无文件需要上传",
        uploadErr: "文件上传失败",
        tip: "您还有文件未上传,您确认仅使用已上传文件吗",
        tipName: "提示",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        saved: "已保存",
        save: "保 存",
      },
      comLibFilter: {
        hide: "隐藏不可选",
        clear: "清除筛选",
        filter: "应用筛选",
        res: "筛选结果",
        none: "暂无属性!",
      },
      libraryGoodInfo: {
        baseAttr: "基本属性",
        capture: "Capture原理图符号",
        altium: "Altium原理图符号",
        concept: "Concept原理图符号",
        foot: "封装符号",
        altiumFoot: "Altium封装符号",
        viewBook: "手册预览",
        partImg: "器件图片",
        noImg: "暂无快照",
        download: "下载",
        viewFile: "文件预览",
        specBook: "规格书",
        brandInfo: "品牌信息",
        certInfo: "认证资料",
        eva: "选型评估表",
        rank: "优选等级",
        spa: "SPA库存",
        morePar: "更多参数",
        name: "名称",
        params: "参数",
        design: "设计追溯",
        model: "模块电路引用",
        simDev: "相似器件",
        fuel: "可替代料",
        cycle: "生命周期",
        noCycle: "该物料没有制造商编码，故无法查看生命周期！",
        threed: "三维模型",
        select: "请选择",
        useEva: "使用评价及反馈",
        his: "历史记录",
        draw: "实物及尺寸图",
        quaInfo: "质量信息",
        bomList: "BOM清单",
        fileSet: "文件管理",
        file: "文件",
        none: "不存在",
        noBook: "该手册不存在！",
        useTotal: "引用总数",
        cbb: "模块电路",
        useNum: "引用数量",
        partLevel: "部品定位等级",
        bkt: "商务等级",
        quaLevel: "质量等级",
        skilLevel: "技术等级",
      },
      applyDataApplyLib: {
        loading: "拼命加载中...",
        submitter: "提交人",
        submitDate: "提交日期",
        pleaseEnter: "请输入",
        autoSearch: "输入后自动搜索器件",
        searchCon: '输入后点击右侧搜索按钮搜索器件',
        getCode: "获取物料编码",
        search: "搜索",
        gain: "获取",
        fillInfo: "搜索到相似器件可快速填写信息",
        pleaseChoose: "请选择",
        selectImg: "选择图片",
        delete: "删除",
        preview: "预览",
        details: "详情",
        uploading: "上传",
        yes: "是",
        no: "否",
        selectOrInput: "请选择或输入",
        uploadImg: "上传图片",
        paramsInfo: "详细参数",
        name: "名称",
        params: "参数",
        inputCon: "请输入内容",
        plmPar: 'PLM参数',
        ci: '次',
        download: "下载",
        selectFile: "选择文件",
        noData: "暂无数据",
        dbSelectCapture: "双击选择Capture原理图符号",
        dbSelectAltium: "双击选择Altium原理图符号",
        dbSelectConcept: "双击选择Concept原理图符号",
        dbSelectPcb: "双击选择Pcb封装",
        dbSelectAltiumPcb: "双击选择Altium封装",
        selectDsn: "请选择DSN文件",
        noSnapshot: "暂无快照",
        dbSelectDsn: "双击选择DSN",
        altiumSymImg: "Altium原理图",
        selectAltiumSymImg: "请选择Altium原理图",
        dbSelectAltiumSym: "双击选择Altium原理图",
        selectMdd: "请选择MDD文件",
        dbSelectMdd: "双击选择MDD",
        selectAltiumPcb: "请选择AltiumPCB",
        dbSelectAdPCB: "双击选择AltiumPCB",
        threed: "3D模型",
        dbSelect3D: "双击此处选择3D",
        olbName: '封装名称',
        packageType: '封装形式',
        version: '原版本号',
        status: '状态',
        createUserName: '上传人',
        createTime: '上传时间',
        newOlb: '新封装',
        newVersion: '新版本号',
        inputVersion: '请输入版本号',
        ope: '操作',
        fzImg: '封装图形',
        upNewfz: '上传新封装',
        upImg: '上传图片',
        save: '保 存',
        uploadPartImg: "上传器件图片",
        selectDataBook: "选择数据手册",
        selectSym: "选择原理图符号",
        selectPcb: "选择PCB封装",
        selectAdSym: "选择Altium符号",
        selectAdPcb: "选择Altium封装",
        select3D: "选择3D",
        selDsn: "选择DSN",
        selMdd: "选择MDD",
        fileSet: "文件管理",
        selFile: '选择文件',
        selNode: '选择驳回节点',
        inputText: '请输入驳回意见',
        back: '驳回',
        noSubmitData: "没有提交的数据",
        pleaseGetCode: "请获取物料编码",
        nextTip: '下一个节点将由',
        nextTip2: '进行审批',
        nextTip3: '请指定下一节点审批人',
        pleaseInput: '请填写',
        upErr: '提交出错',
        upSuc: '提交成功',
        upTip: '已保存数据，待办任务未完成，请注意继续处理待办',
        backTip: '请选择驳回的节点或填写驳回意见！',
        backSuc: '驳回成功',
        agreeTipe: '请输入审批意见!',
        notBlank: "不能为空!",
        opeSuc: "操作成功",
        saveSuc: "保存成功",
        tip: "提示",
        fine: "好",
        confirm: "确定",
        cancel: "取消",
        saveErr: "保存出错",
        upNewOlb: '请上传新封装！',
        fillVer: '请填写选中封装的新版本号！',
        loadDataErr: '数据加载失败！',
      },
      pcbDialog: {
        inputPcbName: "请输入封装名称",
        search: "搜索",
        save: "保存",
        uploadPcb: "上传封装",
        pleaseSelect: "请选择",
        delete: "删除",
        reload: "重新加载",
        loading: "拼命加载中...",
        fileName: "封装名称",
        packageType: "封装形式",
        version: "版本号",
        status: "状态",
        createUserName: "上传人",
        createTime: "上传时间",
        noData: "暂无数据",
        packageAttr: "封装属性",
        pad: "焊盘",
        unit: "单位",
        pinNum: "Pin数量",
        dataErr: "数据加载失败！",
        sameFile: "不能选择同名文件",
      },
      olbLibrary: {
        placeholder: "根据OLB和Package信息搜索",
        search: "搜索",
        save: "保存",
        uploadSym: "上传符号",
        pleaseSelect: "请选择",
        delete: "删除",
        treePlaceholder: "请输入新增的分类名称",
        confirm: "确定",
        cancel: "取消",
        addCate: "新增分类",
        lookAll: "查看全部",
        pla: "请输入搜索内容",
        categoryName: "分类",
        version: "系统版本号",
        inputVersion: "录入版本号",
        status: "发布状态",
        createUserName: "上传人",
        createTime: "上传时间",
        updateUserName: "更新人",
        updateTime: "更新时间",
        remark: "备注",
        editSym: "修改符号",
        editValue: "修改值:",
        editPlace: "请输入修改值",
        sameFile: "不能选择同名文件",
        inputCate: "请输入新增的分类名称",
        nameNoBlank: "名称不能为空",
        noInfo: "暂无相关信息",
        deleteOk: "确定删除",
        tip: "提示",
      },
      dataManual: {
        save: "保 存",
        uploading: "上传中...",
        manufacturer: "制造商：",
        selectMan: "请选择制造商",
        addMan: "点击添加制造商",
        inputDataName: "请输入数据手册名称搜索",
        selected: "已选中",
        item: "条",
        uploadData: "上传数据手册",
        confirmUpload: "确认上传",
        manualName: "数据手册",
        manufacturersName: "制造商",
        status: "状态",
        updateTime: "上传时间",
        userName: "上传人",
        ope: "操作",
        pre: "预览",
        limit: "当前限制选择 100 个文件，本次选择了 ",
        num: "个文件",
        total: "共选择了",
        confirmDelete: "确定移除",
        selectUpload: "请先选择要上传的数据手册",
        other: "默认其他",
        fileSystem: "文件与系统中的",
        statueRepeat: "状态存在重复",
        due: "正式",
        tem: "临时",
        process: "流程中",
        exited: "已存在",
        file: "文件",
        dressing: "临时料",
        refer: "正式料",
        dued: "正式状态已存在文件",
        noWay: "临时料变更申请无法更新正式库!",
        confirmButtonText: "确定",
        temed: "临时状态已存在",
        isGo: "是否继续?",
        flowExit: "流程中状态已存在",
        isCp: "是否覆盖?",
        isUp: "是否更新?",
        tip: "请进行库变更申请，或联系管理员上传。",
        tipTit: "提示",
        opeSuc: "操作成功",
        uploadErr: "上传失败",
        uploadSuc: "上传成功",
        cancelUp: "已取消上传",
        noBook: "该手册不存在！",
      },
      selectImg: {
        confirm: "确定",
        uploadImg: "上传图片",
        selectImg: "选择图片",
        part: "器件",
        limit: "上传图片大小不能超过 2MB!",
      },
      concept: {
        save: "保 存",
        inputCate: "请输入分类名称",
        loading: "拼命加载中...",
        inputSym: "请输入原理图符号名称",
        select: "请选择",
        delete: "删除",
        uploadOne: "单个上传",
        uploadMore: "批量上传",
        categoryName: "分类名称",
        olbName: "名称",
        updateUserName: "上传人",
        updateTime: "上传时间",
        uploadDir: "上传文件夹",
        selectDir: "选择文件夹",
        uploading: "正在上传",
        isHis: "是否历史库",
        hisCate: "历史分类名称",
        pleaseSel: "请选取原理图符号",
        selSym: "请选择符号",
        selCate: "请先选择分类!",
        one: "单个",
        more: "批量",
        upload: "上传成功",
        isGo: "原文件将成为其历史记录，是否继续?",
        tip: "提示",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        canceld: "已取消",
      },
      conceptSvg: {
        noData: "暂无数据",
        impErr: "数据加载失败！",
      },
      similarDevices: {
        simDev: "相似器件",
        speMod: "规格型号",
        encMod: "封装形式",
        pn: "物料编码",
        man: "制造商",
        allSame: "全部相同",
        ass: "点击快速赋值",
        paeSame: "部分相似",
      },
      formHeader: {
        addPart: "新增器件",
        addMode: "新增模块电路",
        enterAndCon: "入库并继续",
        def: "确定入库",
        editPart: "修改器件",
        conEdit: "确定修改",
        copyAdd: "复制新增",
        save: "保存",
        comAndApply: "评论及笔记评审申请",
        addQua: "新增质量信息",
        saveAndAdd: "保存并继续新增",
        editQua: "修改质量信息",
        fillData: "填写数据",
        searchData: "搜索数据",
        deleteData: "删除数据",
        reqShow: "申请编辑回显",
        processImg: "流程图",
        turn: "转办",
        pcbApply: "封装库修改申请",
        symApply: "符号库修改申请",
        pcbAddApply: "封装库新增申请",
        symAddApply: "符号库新增申请",
        CaptureEditApply: "Capture封装库修改申请",
        CaptureSymEditApply: "Capture符号库修改申请",
        submit: "提交",
        pass: "通过",
        reject: "驳回",
        selectNext: "请选择下一步",
        signFor: "签收",
        act: "激活",
        hangUp: "挂起",
        abandon: "废弃",
        inputComm: "请输入意见",
        affirm: "确认",
        parts: "元器件",
        sureButton: "确定",
        processSub: "流程挂起",
        processLun: "流程激活",
        tip: "此操作将永久废弃流程, 是否继续?",
        tipName: "提示",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        abaSuc: "流程废弃成功",
        cancelOpe: "已取消废弃操作",
        selectBra: "请先选择分支",
        app: "审批",
      },
      applyHistory: {
        appHis: "审批历史",
        appStep: "审批流程节点:",
        appCon: "审批内容:",
        appUser: "审批人:",
        noData: "暂无审批历史！",
      },
      threeD: {
        search: "搜索",
        save: "保存",
        uploadThree: "上传三维模型",
        loading: "拼命加载中",
        fileName: "文件名称",
        userName: "上传人",
        status: "状态",
        createTime: "上传时间",
        noData: "3D模型文件缺失",
        noSelData: "未选择任何数据",
      },
      selectDsn: {
        nameSearch: "文件名搜索",
        fileName: "文件名称",
        otherFileName: "清单文件",
        userName: "上传人",
        updateTime: "上传时间",
        status: "状态",
        versionNo: "版本号",
        remark: "备注",
        img: "快照",
        noData: "暂无快照",
        bomList: "BOM清单",
        goodsKeyComponent: "关键器件",
        checkComponent: '是否校验列',
        references: '位号',
        quantity: '数量',
      },
      padFlashShapeOsmBsm: {
        bsm: "板框",
        osm: '图框',
        inputFile: "请输入文件名称",
        otherFileName: "Dra文件名称",
        fileName: "文件名称",
        userName: '上传人',
        updateTime: '上传时间',
        status: '状态',
        versionNo: '版本号',
        ope: '操作',
        other: '其他',
        selOrUp: '请选择或上传文件',
        noSame: '不能选择同名文件',
        downFile: '下载文件',
        downFsm: '下载fsm',
        downSsm: '下载ssm',
        downBsm: '下载bsm',
        downOsm: '下载osm',
        downDra: '下载dra',
      },
      altiumSymbolAndFoot: {
        inputName: "请输入名称",
        uploadSym: '上传符号',
        lookAll: '查看全部',
        filter: '输入关键字进行过滤',
        componentsName: '符号名称',
        version: '版本号',
        status: '状态',
        userName: '上传人',
        createTime: '上传时间',
        remark: '备注',
        ope: '操作',
        down: '下载',
        addCate: '添加分类',
        inputCate: '请输入分类名称',
        sym: '符号',
        addSuc: '添加成功',
        uploadFoot: '上传封装',
        pleaseSelect: '请选择',
        footName: '封装名称',
        foot: '封装'
      },
      altiumFile: {
        inputFile: '请输入文件名称',
        fileName: "文件名称",
        schLib: '原理图库',
        billFileName: '清单文件',
        userName: '上传人',
        createTime: '上传时间',
        img: '快照',
        noImg: '暂无快照',
        remark: '备注',
        bomList: 'BOM清单',
        goodsKeyComponent: '关键器件',
        checkComponent: '是否校验列',
        references: '位号',
        quantity: '数量',
      },
      tableMessageEf62: {
        fileName: "文件名称",
        dzwl: "电子物料",
        dwgId: "图纸ID",
        filePath: "文件路径",
        description: "描述",
        ope: "操作",
        down: '下载',
      },
      similarDevice: {
        allSame: '完全相似',
        parSame: '部分相似',
        productModel: '规格型号',
        packageType: '封装类型',
        manufacturer: '制造商名称',
        partNumber: '物品编号',
      },
      alternativeMaterial: {
        addReplace: '新增可替代料',
        our: '匹配方式',
        match: '匹配度',
        ope: '操作',
        moreOpe: '更多操作',
        bilateralMatching: '双向匹配',
        unilateralMatching: '单向匹配',
        setMatch: '设置单向匹配',
        setMoreMatch: '设置双向匹配',
        noReplace: '暂无可替代料！',
        lookAll: '查看全部属性',
        lookSame: '查看相同属性',
        lookNoSame: '查看不同属性',
        attr: '属性',
        isConst: '是否一致',
        rawMaterials: '原物料',
        replaceMat: '替代物料',
        addRep: '添加可替代物料',
        onePlay: '单向替代',
        twoPlay: '双向替代',
        setPlay: '设置替代料匹配度',
        oneSuc: '成功设置成单向匹配',
        twoSuc: '成功设置成双向匹配',
        added: '已添加此物料',
        selectPart: '请选择一条物料',
        noSelSelf: '不能选择物料自身',
        addSuc: '添加成功',
        tip: '提示',
        goOn: '继续',
      },
      evaluationFeedback: {
        exportRec: '导出记录',
        add: '新增',
        wrongback: '错误反馈',
        deleteBack: '删除反馈',
        editBack: '修改反馈',
        edited: '已修改',
        reOpen: '重新打开',
        confirmEdit: '确认修改',
        noBack: '该器件暂无错误反馈！',
        evaluation: '使用评价',
        deletepj: '删除评价',
        editpj: '修改评价',
        nopj: '该器件暂无使用评价！',
        notes: '个人笔记',
        deleteNote: '删除笔记',
        editNote: '修改笔记',
        noNote: '暂无笔记',
        addErr: '添加错误类型',
        inputValue: '请输入值',
        ope: '操作',
        edit: '修改',
        delete: '删除',
        inputpjValue: '请输入评价内容',
        setpj: '设置评价星级',
        selectErr: '请选择错误类型',
        clickAddErr: '点击添加错误类型',
        inputErrValue: '请输入错误反馈内容',
        inputbj: '请输入笔记',
        addpj: '添加评价',
        addbj: '添加笔记',
        delSuc: '删除成功',
        confirmDel: '确定删除笔记',
        tip: '提示',
        inputNote: '请输入内容',
        inputErrType: '请输入错误类型的值',
        inputpj: '请填写评价信息',
        inputErrBack: '请填写错误反馈信息',
        inputbj2: '请填写笔记',
      },
      physicalDimensional: {
        pic: '实物图',
        partImg: '器件图片',
        noImg: '暂无图片',
        outlineDrawing: '外形尺寸图',
      },
      qualityInformation: {
        partNumber: '物料编码',
        partCategory: '物料分类',
        model: '规格型号',
        isDisabled: '是否禁用',
        yes: '是',
        no: '否',
        DPAInfo: 'DPA信息',
        DPARes: 'DPA结论',
        selectDPARes: '请选择DPA结论',
        qualified: '合格',
        yphg: '样品合格',
        dubious: '可疑',
        disqualification: '不合格',
        unverified: '未验证',
        quotedStandard: '引用标准',
        testItem: '检测项目',
        testReport: '检验报告',
        affix: '附件',
        remark: '备注',
        jmfx: '假冒翻新',
        isJmfx: '是否假冒翻新',
        fxInfo: '翻新说明',
        jmType: '假冒类型',
        kkjm: '空壳假冒',
        fzjm: '仿制假冒',
        hxqs: '焊线缺失',
        gzgl: '故障归零',
        isgzgl: '是否故障归零',
        gsTime: '故障发生时间',
        selectDate: '选择日期',
        selectTime: '选择时间',
        gzInfo: '故障基本情况',
        dwqk: '定位情况',
        cslsqk: '措施落实情况',
        jyfsqk: '举一反三情况',
        zmcl: '证明材料和清单',
        zlfxpj: '质量分析及评价',
        pjwd: '评价维度',
        xzpjwd: '请选择评价维度',
        sysm: '使用寿命',
        cmdj: '潮敏等级',
        qt: '其他',
        qljl: '评价结论'
      },
      bomList: {
        goodsKeyComponent: '关键器件',
        checkComponent: '是否校验列',
        references: '位号',
        quantity: '数量',
      },
      moduleCircuit: {
        noRole: "无权限查看",
      },
      showSvg: {
        foot: "封装",
        sym: "符号",
        adSym: '这是原理图的web预览，如果有多个原理图的话，仅显示第一个。',
        adFoot: '这是封装的web预览，如果有多个封装的话，仅显示第一个。',
        name: '名称',
        selectConcept: '请选择Concept原理图',
        noData: '暂无数据!',
        packageAttr: 'Package属性',
        partAttr: 'Part属性',
      },
      showPCBMsg: {
        pcbImg: '封装图形',
        noData: '暂无数据',
        footAttr: '封装属性',
        hanp: '焊盘：',
        unit: '单位：',
        pinNum: 'Pin数量：',
      },
      synergyOlb: {
        olbList: '符号库列表',
        reload: '重新加载',
        inputolbname: '请输入符号库名称',
        inputOlb: '请输入原理图符号',
        name: '名称',
        createTime: '创建时间',
        olbImg: '原理图符号',
        noData: '暂无数据',
        foot: "封装",
        selectPcb: '请选择PCB封装',
        packageAttr: 'Package属性',
        partAttr: 'Part属性',
        loadErr: '数据加载失败！',
      },
      mixin: {
        select: '请先选择',
        file: '文件',
        noEx: '不存在',
        downErr: '未找到数据，下载出错',
        gesErr: '下载数据格式不正确!',
        saveSuc: '保存成功',
        nolinshi: '该物料不是临时料',
        nozhengshi: '该物料不是正式料',
        isShji: '物料正在升级过程中，无法提起申请。',
        isShchu: '物料正在删除过程中，无法提起申请。',
        olbLib: '原理图库',
        pcbLib: '原理图库',
        nothing: '什么都不是',
        noBook: '该手册不存在！',
        footType: '封装类型',
        man: '制造商',
        sup: '供应商',
        Mon: '一',
        Tue: '二',
        Wed: '三',
        Thu: '四',
        Fri: '五',
        Sat: '六',
        Sun: '日',
        adSym: 'Altium原理图',
        zsl: '正式料',
        lsl: '临时料',
        showData: '回显数据',
        delData: '删除数据',
        searchData: '搜索数据',
        limitPrice: '当前价格字段不合法，已自动重置为0',
        part: '元器件',
        getErr: '获取物料类型出错',
      },
      changeApprovalUser: {
        node: '节点名称：',
        user: '审批人：',
        noChange: '该节点被设置为不允许修改审批人。',
        change: '想改变下一节点的审批人员？请在下方选择新的审批人。',
        selectUser: '请选择人员',
        appr: '请指定下一节点审批人',
        bh: '驳回',
        shp: '审批',
        tj: '提交',
        fzk: '封装库转生产申请',
        noMet: '未获取到按钮方法，请刷新重试',
      },
      pushTC: {
        tcId: 'TC编号',
        tcRev: 'TC版本',
        idCard: '身份证号',
        selectFile: '选择文件',
        index: '序号',
        fileName: '文件名称',
        fileType: '文件类型',
        inputCon: '请输入内容',
        ope: '操作',
        tquBom: '提取BOM上传TC',
        up: '上传',
        ylt: '原理图',
        yltpdf: '原理图PDF',
        yzb: '印制板图',
        zpt: '装配图',
        yqjb: '元器件表',
        jxb: '接线表',
        qt: '其他',
        shurID: '请输入TC编号',
        shurVer: '请输入TC版本',
        shurIdCard: '请输入身份证号',
        exited: '上传列表已存在',
        buchf: '不重复添加，其他文件正常添加到列表',
        noidCard: '身份证号格式不正确',
        noData: '您未上传任何文件，是否继续提取BOM上传TC?',
        tip: '提示',
        yes: '是',
        no: '否',
        nofile: '文件列表不能为空',
        notype: '的文件类型不能为空',
        upSuc: '上传文件成功',
        upErr: '上传文件失败',
        tiquBom: '正在提取BOM上传IC',
        tqBomSuc: '提取BOM上传TC成功',
        tqBomErr: '提取BOM上传TC失败',
      },
      bomOutput: {
        outputBom: '输出BOM',
        get: '获取当前工程',
        add: '添加工程',
        bomyt: 'BOM用途',
        xp: '小批',
        hj: '核价',
        pc: '批产',
        xpywc: '小批已完成',
        cpxh: '产品型号',
        tzm: '特征码',
        zbbh: '总版本号',
        cpmc: '产品名称',
        bbh: '版本号',
        zth: '总图号',
        bzh: '标准化',
        bz: '编制',
        jd: '校对',
        shh: '审核',
        shd: '审定',
        shuju: '数据',
        index: '序号',
        name: '工程名称',
        yzbmc: '印制板名称',
        pcbamc: 'PCBA名称',
        shrnr: '请输入内容',
        pcbmc: 'PCB名称',
        pcbbgg: 'PCB板规格',
        pcbbh: 'PCB版号',
        pcbbid: 'PCB板ID',
        th: '图号',
        pcbath: 'PCBA图号',
        ope: '操作',
        xzpcb: '选择PCB',
        gnb: '功能板',
        dyb: '电源板',
        xsb: '显示板',
        txb: '通讯板',
        jpb: '键盘板',
        hxb: '核心板',
        qit: '其他',
        noName: '未获取到工程名称',
        noId: '未查询到该ID，请重新输入',
        more: '查询到多条数据，请重新输入',
        noBom: '无工程名称，无需输出BOM',
        bomFile: 'BOM文件.xlsx',
        shcErr: '审查工程错误',
      },
      chart: {
        smzq: '生命周期统计图',
        qjzl: '器件种类',
      },
      deviceCompare: {
        lookAll: '查看全部属性',
        lookSame: '查看相同属性',
        lookNo: '查看不同属性',
        export: '导出报表',
        add: '新增对比',
        attr: '属性',
        isSame: '是否一致',
        baseAttr: '基本信息',
        more: '更多参数',
        inputPn: '请输入物料编码',
        addDev: '添加',
        limit: '器件数量小于2，无法比较导出',
        tip: '确认将器件移出对比?',
        tipName: '提示',
        limit2: '器件数量小于2，无法比较!',
        delSuc: '删除成功',
        fileAttr: '文件属性',
        inputCon: '请先输入内容',
        noCon: '搜索内容不存在',
        hasSame: '已存在相同器件',
        addSuc: '添加成功',
      },
      drawingManual: {
        mstz: '描述图纸',
        nopn: '缺少partNumber'
      },
      updateCompare: {
        warnTitle: '可更新数据表格中奇数序号是上传文件数据，偶数序号是数据库数据，两行数据为一组对比结果。',
        haveData: '有更新数据',
        index: '序号',
        excelNum: 'Excel行数',
        tip: '两行数据为一组对比结果，',
        tip2: '共',
        tip3: '组',
        noData: '无更新数据',
        noPn: '无物料编码数据',
      },
      partimpoartshow: {
        loadingText: '正在进行数据校验，请耐心等待！',
        confirmInp: '确认导入',
        impSuc: '导入成功',
        back: '返 回',
        excel: 'Excel第',
        pnRepeat: '行物料编码重复',
        pnNo: '行物料类型不匹配',
        dataRepeat: '行数据重复',
        cate: '分类',
        noRole: '找不到相对应的生成物料编码规则'
      },
      dataBookImport: {
        step: '操作步骤：',
        step1: '1、下载对应的《批量导入模板》',
        btn: '立即下载《批量导入模板》',
        step2: '2、根据模板整理相应数据，为保障粘贴信息被有效导入，请使用纯文本或数字',
        step3: '3、信息输入完毕，点击下方按钮 选取文件',
        step4: '4、预览检查数据(如有问题则会标识出来,敬请改正)',
        step5: '5、确认导入',
        step6: '6、数据手册重复的情况下是否更新数据',
        yes: '是',
        no: '否',
        clickUp: '点击上传',
        imp: '导入',
        selectCate: '请选择分类',
        di: '第',
        pnRepeat: '行物料编码重复',
        confirmDel: '确定移除',
        limit: '当前限制选择 1 个文件，本次选择了',
        tip: '个文件，共选择了',
        num: '个文件',
      },
      dataBookimpoartshow: {
        loadText: '正在进行数据校验，请耐心等待！',
        dataBook: '数据手册',
        repeat: '名称重复',
        di: '第',
        limit1: '行的数据手册名称不能为空',
        limit2: '行的制造商名称不能为空',
        confirmImp: '确认导入',
        back: '返 回',
        impSuc: '导入成功',
      },
      componentsUpdate: {
        step: '操作步骤：',
        step1: '1、下载对应的《批量更新模板》',
        down: '立即下载《批量更新模板》',
        step2: '2、导入excel文件',
        step3: '3、对excel字段和数据库字段进行匹配',
        step4: '4、点击确认更新按钮进行器件更新',
        clickUp: '点击上传',
        clickPre: '点击预览',
        comPre: '对比预览',
        pipField: '匹配字段：',
        tip: '注：必选字段为',
        conUpdate: '确定更新',
        reset: '重 置',
        back: '返 回',
        impField: '导入字段',
        dbField: '数据库字段',
        title: '导入字段《--》数据库字段',
        errRes: '更新失败原因',
        gon: '共',
        num: '条数据',
        preData: '预览数据',
        selField: '请选择字段进行匹配！',
        selypp: '请选择已匹配的字段！',
        selImp: '请选择导入匹配字段',
        suc: '成功',
        upSuc: '更新成功',
        limit: '当前限制选择 1 个文件，本次选择了',
        tip2: '个文件，共选择了',
        num2: '个文件',
        conDel: '确定移除',
        upLimit: '上传文件只能是.xlsx格式!',
        upFile: '请先上传文件!',
        ppField: '请先匹配字段',
      },
      componentsLeadin: {
        step: '操作步骤：',
        step1: '1、下载对应的《批量导入模板》',
        down: '立即下载《批量导入模板》',
        step2: '2、导入excel文件',
        step3: '3、对excel字段和数据库字段进行匹配',
        step4: '4、点击确认导入按钮进行器件导入',
        clickUp: '点击上传',
        clickPre: '点击预览',
        pipField: '匹配字段：',
        tip: '注：必选字段为',
        conUpdate: '确定导入',
        reset: '重 置',
        back: '返 回',
        impField: '导入字段',
        dbField: '数据库字段',
        title: '导入字段《--》数据库字段',
        errRes: '导入失败原因',
        gon: '共',
        num: '条数据',
        preData: '预览数据',
        selField: '请选择字段进行匹配！',
        selypp: '请选择已匹配的字段！',
        selImp: '请选择导入匹配字段',
        suc: '成功',
        upSuc: '导入成功',
        limit: '当前限制选择 1 个文件，本次选择了',
        tip2: '个文件，共选择了',
        num2: '个文件',
        conDel: '确定移除',
        upLimit: '上传文件只能是.xlsx格式!',
        upFile: '请先上传文件!',
      },
      componentsImport: {
        step: '操作步骤：',
        step1: '1、下载对应的《批量导入模板》',
        btn: '立即下载《批量导入模板》',
        step2: '2、根据模板整理相应数据，为保障粘贴信息被有效导入，请使用纯文本或数字',
        step3: '3、信息输入完毕，将文件拖入下方方框中，或者点击选取',
        step4: '4、预览检查数据(如有问题则会标识出来,敬请改正)',
        step5: '5、确认导入',
        step6: '6、物料编码重复的情况下是否更新数据',
        yes: '是',
        no: '否',
        clickUp: '点击上传',
        imp: '导入',
        selCate: '请选择分类',
        limit: '当前限制选择 1 个文件，本次选择了',
        tip2: '个文件，共选择了',
        num2: '个文件',
        conDel: '确定移除',
        di: '第',
        pnRepeat: '行物料编码重复',
        opeSuc: '操作成功',
      },
      BOMMatches: {
        upBom: 'BOM上传',
        selBom: 'BOM选择数据',
        bompp: 'BOM匹配',
        getForm: '生成表单',
        yipp: '已匹配',
        num: '个型号',
        addPart: '增加元器件',
        pptj: '匹配条件：',
        pp: '匹配',
        exportPp: '导出匹配结果',
        index: '序号',
        productModel: '规格型号',
        info: '详情',
        ppxh: '匹配型号',
        ppd: '匹配度：',
        kppxh: '可匹配型号',
        nopp: '暂无更多匹配',
        more: '更多匹配',
        notFound: '未发现匹配型号',
        dataBook: '数据手册',
        ope: '操作',
        addPart2: '添加元器件',
        save: '保 存',
        delSuc: '删除成功',
      },
      BOMform: {
        upBom: 'BOM上传',
        selBom: 'BOM选择数据',
        bompp: 'BOM匹配',
        getForm: '生成表单',
        name: 'BOM表单名称：BOM模板',
        export: '导出xls文件',
        t1: '全选（可选属性）',
        t2: '全选（导出属性）'
      },
      BOMchoosedate: {
        upBom: 'BOM上传',
        selBom: 'BOM选择数据',
        bompp: 'BOM匹配',
        getForm: '生成表单',
        tip: '选择每一列对应的数据类型，完成后点击［开始匹配］按钮进入下一步。',
        startpp: '开始匹配',
      },
      propertyMaintenance: {
        fzxs: '封装形式',
        yxdj: '优选等级',
        cmdj: '潮敏等级',
        jdmgdj: '静电敏感等级',
        addTrr: '新增属性值',
        attr: '属性值',
        createTime: '创建时间',
        remark: '备注',
        ope: '操作',
        edit: '修改',
        title: '属性维护',
        attrName: '属性名称：',
        selAttr: '请选择属性',
        sort: '顺序号',
        inputAttr: '请输入属性名称',
        inputName: '请输入属性简称',
        enable: '启用',
        dis: '停用',
        opeSuc: '操作成功'
      },
      partsLoader: {
        cms: '元器件',
        cbb: '模块电路',
        col: '自选库',
        preCms: '优选元器件库',
        runWay: '运行客户端方法',
        inputCate: '请输入分类名称',
        expandAllNode: '展开所有节点',
        hiddenAllNode: '折叠所有节点',
        searchPlace: '快速搜索，多条件搜索以"|"分割，示例:DH|电阻',
        ple: '请按',
        attrSearch: '属性搜索',
        search: '搜索',
        clear: '清空',
        refresh: '刷新',
        advSearch: '高级搜索',
        jzAd: '校正Altium封装、符号',
        equal: '等于',
        noequal: '不等于',
        dy: '大于',
        xy: '小于',
        dd: '大于等于',
        xd: '小于等于',
        inc: '包含',
        input: '输入',
        addCon: '添加搜索条件',
        selCate: '请先选择物料分类！',
        opr: '操作',
        moreOpe: '更多操作',
        no: '否',
        yes: '是',
        searchKey: '搜索关键器件的模块电路',
        model2d: '2D模型',
        model3d: '3D模型',
        specAttr: '特殊属性',
        name: '名称',
        par: '参数',
        plmAttr: 'PLM属性',
        ggs: '规格书',
        ppxx: '品牌信息',
        rzzl: '认证资料',
        xxpgb: '选型评估表',
        yxdj: '优选等级',
        dataBook: '数据手册',
        noDataBook: '暂无数据手册',
        evaluation: '错误反馈及使用评价',
        add: '新增',
        cwfk: '错误反馈',
        delfk: '删除反馈',
        exitd: '已修改',
        confirmEdit: '确认修改',
        nocwfk: '该器件暂无错误反馈！',
        sypj: '使用评价',
        delpj: '删除评价',
        nopj: '该器件暂无使用评价！',
        save: '保 存',
        inputpj: '请输入评价内容',
        setpj: '设置评价星级',
        selType: '请选择错误类型',
        clickAddType: '点击添加错误类型',
        inputErr: '请输入错误反馈内容',
        addType: '添加错误类型',
        inputValue: '请输入值',
        errType: '错误类型',
        edit: '修改',
        flstdh: '分类视图导航',
        yxyqjk: '优选元器件库',
        wlfldh: '物料分类导航',
        zzsdh: '制造商导航',
        gctdk: '国产替代库',
        sx: '筛选',
        sqsx: '收起筛选',
        pn: '物料编码',
        notzc: '不支持Deehow CMS Pro',
        tjpj: '添加评价',
        txpjxx: '请填写评价信息',
        addSuc: '添加成功',
        txcwxx: '请填写错误反馈信息',
        delSuc: '删除成功',
        noDataBook2: '该手册不存在！',
        status: '状态',
        code: '编码',
        createTime: '创建时间',
        ppxh: '品牌型号',
        pp: '品牌',
        bprzbz: '部品认证标识',
        yxzt: '有效状态',
        cjr: '创建人',
        gxsj: '更新时间',
        gxr: '更新人',
        version: '版本',
        file: '文件',
        bpdwdj: '部品定位等级',
        swdj: '商务等级',
        zldj: '质量等级',
        jsdj: '技术等级',
        mddFile: 'MDD文件:',
        down: '下载',
        partImg: '器件图片',
        noImg: '暂无图片',
        noData: '暂无数据',
        adImg: 'Altium原理图:',
        dsnFile: 'DSN文件:',

      },
    },
    // 我的自选库
    mycollection: {
      main: {
        cateName: '请输入分类名称',
        ksss: '快速搜索',
        ple: '请按',
        attrSearch: '属性搜索',
        attrName: '请输入属性名称',
        attr: '请输入属性简称',
      }
    },
    // 常用库
    usedMycollection: {
      ksss: '快速搜索',
      ple: '请按',
      attrSearch: '属性搜索',
      exportGood: '导出器件',
      exportSel: '导出勾选器件',
      exportAll: '导出全部器件',
      yypc: '引用频次',
      attrName: '请输入属性名称',
      attr: '请输入属性简称',
      limit: '请勾选导出的器件',
      exporting: '正在导出器件...',
      exportSuc: '导出成功',
    },
    // 评价及反馈
    evaluationFeedback: {
      cwfk: '错误反馈',
      sypj: '使用评价',
      grbj: '个人笔记',
      to: '至',
      startTime: '开始日期',
      endTime: '结束日期',
      export: '导出',
      exportRecord: '导出记录',
      selectType: '请选择导出的类型!',
      fileName: '反馈及评价导出记录.xlsx',
      pn: '物料编码',
      noteContent: '笔记内容',
      createUserName: '创建人',
      createTime: '反馈时间',
      updateUserName: '更新人',
      updateTime: '更新时间',
      ope: '操作',
      isDel: '确定删除吗？',
      lookAll: '查看全部',
      lookMine: '查看我的',
      delSuc: '删除成功！',
      delErr: '删除失败！',
      commentContent: '评价内容',
      commentRate: '评价量级',
      feedbackType: '错误类型',
      isComplete: '是否修改完成',
      yes: '是',
      no: '否',
      feedbackContent: '反馈内容',
    },
    // 统计
    statistical: {
      dull: '呆滞料',
      lsl: '临时料',
      jyl: '禁用料',
      ge: '个',
      sjxy: '设计选用',
      selectYear: '请选择年份',
      yqjktji: '元器件库统计',
      mxktji: '模型库统计',
      libType: '请选择库类型',
      symLib: '符号库',
      capSym: 'Capture符号',
      adLib: 'Altium符号库',
      adSym: 'AD符号',
      adFootLib: 'Altium封装库',
      adFoot: 'AD封装',
      conceptLib: 'Concept符号库',
      conceptSym: 'Concept符号',
      footLib: '封装库',
      foot: 'Allegro封装',
      three: '三维库',
      yxdj: '优选等级',
      smzq: '生命周期',
      yqjs: '元器件数',
      mkdls: '模块电路数',
      con: '国别',
    },
    // 元器件管理设置
    partsSetting: {
      set: '设置',
      status: '状态颜色',
      by: '为',
      setColor: '的不同状态设置颜色',
      set2: '设 置',
      yxdj: '优选等级',
      setYxdj: '的优选等级',
      part: '元器件',
      aqdj: '安全等级',
      setAqdj: '的安全等级',
      ztyxj: '状态优先级',
      tz: '调整',
      setZtyxj: '的状态优先级',
      sync: '设计端同步元器件',
      setSync: '设置设计端同步元器件的条件',
      back: '返回上一级',
      partcolor: {
        colorSet: '状态颜色设置',
        jyl: '禁用料',
        lsxywl: '临时选用物料',
        dzl: '呆滞料',
        dzrq: '呆滞日期：',
        limitNum: '只能输入数字！',
        sel: '请选择',
        day: '天',
        week: '周',
        mon: '月',
        year: '年',
        processStatus: '流程中状态',
        setSuc: '设置成功',
        setErr: '设置失败！！！',
      },
      partyouxuan: {
        yxdjSet: '优选等级设置',
        yxdj: '优选等级',
        colorSet: '颜色设置',
        desc: '描述',
        ope: '操作',
        up: '上移',
        down: '下移',
        addyxdj: '添加优选等级',
        saveSuc: '保存成功',
        fillName: '请将名称填写完整',
        nameRepeat: '名称重复',
        delSuc: '删除成功',
      },
      partweihu: {
        kwhx: '可维护项设置',
        smzq: '生命周期状态',
        attr: '属性值',
        remark: '备注',
        ope: '操作',
        add: '添加生命周期',
        cmdj: '潮敏等级',
        jdmgdj: '静电敏感等级',
        saveSuc: '保存成功',
        delSuc: '删除成功',
        noDel: '该属性正在被物料使用，不能删除！！！',
      },
      partstate: {
        ztyxj: '状态优先级设置',
        status: '状态',
        ope: '操作',
        setSuc: '设置成功',
      },
      partsameshow: {
        tbqj: '同步器件设置',
        allow: '允许',
        notAllow: '不允许',
        isAllowSym: '是否允许无Capture符号的元器件同步',
        isAllowAdSym: '是否允许无AD符号的元器件同步',
        isAllowAdFoot: '是否允许无AD封装的元器件同步',
        isAllowStop: '是否允许停用的元器件同步',
        isAllowDull: '是否允许呆滞料同步',
        isAllowRep: '是否允许作废的元器件同步',
      },
      partSecurity: {
        aqdjSet: '安全等级设置',
        aqdj: '安全等级',
        colorSet: '颜色设置',
        desc: '描述',
        ope: '操作',
        up: '上移',
        down: '下移',
        addaqdj: '添加安全等级',
        saveSuc: '保存成功',
        fillName: '请将名称填写完整',
        nameRepeat: '名称重复',
        delSuc: '删除成功',
      }
    },
    // 回收站
    recycleBin: {
      ksss: '快速搜索',
      ple: '请按',
      attrSearch: '属性搜索',
      del: '彻底删除',
      delTime: '删除时间',
      delUser: '删除人',
      ope: '操作',
      recover: '恢复',
      fillName: '请输入属性名称',
      fillAbb: '请输入属性简称',
      tip: '删除的数据无法恢复，是否继续?',
      tipName: '提示',
      recSuc: '恢复成功',
    },
    // 目录类型管理
    drectoryManage: {
      addName: '新增',
      updateName: '修改',
      deleteName: '删除',
      dicType: '目录类型',
      dicLevel: '目录等级',
      dicName: '目录名称',
      addDicType: '新增目录类型',
      editDicType: '修改目录类型',
      dicTypeSet: '目录类型管理',
      inputNumLevel: '请输入数字等级',
      fillDicTypeName: '请填写目录类型名称',
      fillDicLevel: '请填写目录类型等级',
      limit: '目录类型等级不能重复',
      editSuc: '修改成功',
      addSuc: '添加成功',
      tip: '确定删除该目录类型？',
      tipName: '提示',
      delSuc: '删除成功',
      cancelDel: '已取消删除',
    },
    // 数据手册
    datasheet: {
      search: {
        placeholder: "请按数据手册名称和制造商名称进行检索",
        treePlaceholder: "请输入搜索内容"
      },
      tableHeader: {
        categoryName: "分类名称",
        manualName: "数据手册",
        manufacturersName: "制造商",
        status: "状态",
        createTime: "上传时间",
        userName: "上传人",
        description: "说明",
        remark: "备注",
        operate: "操作",
      },
      btn: {
        treeBtn: {
          AddFirstLevelClassification: "新增一级分类",
          viewAll: "查看全部",
        },
        treeMenuBtn: {
          edit: "修改分类",
          delete: "删除分类",
          addSubordinate: "添加分类",
        },
        tableBtn: {
          headerBtn: {
            updateAll: "更新全部手册的制造商",
            upload: "上传数据手册",
            move: "移动手册分类",
            recycleBin: "回收站",
          },
          operateBtn: {
            update: '更新制造商',
            preview: '预览',
            edit: '编辑',
            historicVersion: '历史版本',
            delete: '删除'
          }
        }
      },
      modal: {
        updateCategory: {
          title: '添加分类',
          editTitle: '编辑分类',
          content: {
            form: {
              categoryName: "分类名称",
            },
            placeholder: {
              categoryName: "请输入分类名称",
            }
          },
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
          tips: {
            categoryNameEmptyTips: "分类名称不能为空！",
          }
        },
        updateManual: {
          title: '上传数据手册',
          editTitle: '编辑',
          content: {
            form: {
              manufacturer: "制造商",
              classification: "分类",
              status: "状态",
              description: "说明",
              manualName: "名称",
              inputVersion: "录入版本号",
              description: "描述",
              changeDescription: "更改说明",
              remark: "备注",
            },
            placeholder: {
              manufacturer: "请选择制造商",
              classification: "请选择分类",
              description: "请输入说明",
            },
            options: {
              statusOption1: '正式',
              statusOption2: '临时',
            }
          },
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
          tips: {
            fileEmptyTips: "请先选择要上传的数据手册",
            classificationEmptyTips: "请选择分类！",
            addManufacturerTips: "点击添加制造商",
            uploadTips: "上传数据手册",
            fileSizeLimitTips: "单个文件大小不超过200M",
            fileTotalSizeLimitTips: "文件总大小超过200M，请重新选择上传文件",
            fileNumberLimitTips: "当前限制选择 100 个文件，本次选择了 { length1 } 个文件，共选择了 { length2 } 个文件",
            removefileTips: "确定移除 { name }？",
            fileWithTheSameNameTips: "不能选择同名文件",
          }
        },
        duplicateFiles: {
          title: '更新',
          tableHeader: {
            manualName: "数据手册",
            manufacturersName: "制造商",
            status: "状态",
            createTime: "上传时间",
            description: "说明",
          },
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
        },
        duplicateFilesPrompt: {
          title: '提示',
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
          tips: {
            delTips: "文件 { tipInfo } 已存在  是否更新",
            cancelTips: "已取消更新"
          },
        },
        addManufacturer: {
          title: '制造商',
          content: {
            form: {
              name: "公司名称",
              abbreviation: "公司简称",
              differentCountries: "地址",
              website: "网址",
              phone: "联系方式",
              fax: "传真",
              email: "邮箱",
              oldName: "历史曾用名",
              intro: "公司简介",
              remark: "备注",
              logo: "Logo",
            },
            formRules: {
              name: "请输入公司名称",
              abbreviation: "请输入公司简称",
              email: "长度在 7 到 200 个字符",
            },
          },
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
          tips: {
            logoTips: "只能上传jpg/png文件",
            websiteTips: "请输入合法网址",
            phoneTips: "请输入合法手机号",
            faxTips: "传真格式不符合规范",
            emailTips: "请输入合法邮箱",
          }
        },
        moveClassificationManual: {
          title: "移动分类手册",
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
          tips: {
            emptyClassificationTips: "请选择目标分类！",
          }
        },
        recycleBinModal: {
          title: '回收站',
          search: {
            placeholder: "请按数据手册名称和制造商名称进行检索",
          },
          content: {
            tableHeader: {
              categoryName: "分类",
              manualName: "数据手册",
              manufacturersName: "制造商",
              status: "状态",
              userName: "上传人",
              createTime: "上传时间",
              updateTime: "删除时间",
              operate: "操作",
            },
            btn: {
              tableBtn: {
                headerBtn: {
                  delete: "删除 ",
                },
                operateBtn: {
                  historyRecord: '历史记录',
                  recover: '恢复',
                  preview: '预览',
                }
              }
            },
          },
          tips: {
            manualEmptyTips: "请先选择数据手册!",
          }
        },
        renameModal: {
          content: {
            placeholder: {
              name: "请输入文件名称",
            },
            btn: {
              rename: "重命名",
              revertToHistory: "恢复成历史记录",
            },
          },
          footerBtn: {
            confirm: '确定',
          },
          tips: {
            nameEmptyTips: "名称不能为空！",
          }
        },
        historicVersion: {
          title: '历史版本',
          content: {
            tableHeader: {
              manualName: "数据手册",
              versionNumber: "版本号",
              status: "状态",
              userName: "上传人",
              createTime: "上传时间",
              operate: "操作",
            },
            btn: {
              tableBtn: {
                operateBtn: {
                  preview: '预览',
                }
              }
            },
          },
        },
        deletePrompt: {
          title: '提示',
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
          tips: {
            delTips: "是否确定删除该手册?",
            cancelTips: "已取消删除"
          },
        },
        updateCanclePrompt: {
          title: '提示',
          footerBtn: {
            cancel: '取消',
            confirm: '确定',
          },
          tips: {
            delTips: "是否确定取消?",
            cancelTips: "已取消删除"
          },
        },
      },
      tips: {
        manualEmptyTips: "该手册不存在！",
      },
    },
    // 待办审批
    waitDo: {
      appCms: '发起元器件流程',
      appCbb: '发起模块电路流程',
      appCom: '发起通用电路流程',
      dsp: '待审批',
      inputPn: '请输入料号',
      inputUser: '请输入申请人',
      status: '状态',
      dcl: '待处理',
      splx: '审批类型',
      pn: '料号',
      name: '名称',
      wllx: '物料类型',
      sqr: '申请人',
      curNodeName: '当前执行节点',
      approveTime: '上一节点审批时间',
      yqjdtj: '元器件待提交',
      recallTag: '被撤回',
      yes: '是',
      no: '否',
      ope: '操作',
      del: '删除',
      cbbdtj: '模块电路待提交',
      fqsq: '发起申请',
      sqsj: '申请数据',
      jksq: '建库申请',
      pljbbsq: '评论及笔记评审申请',
      wscfz: '未生产封装',
      sub: '提交',
      inputPackageName: '请输入封装名称',
      olbName: '封装名称',
      packageType: '封装形式',
      processStatus: '流程状态',
      version: '原版本号',
      createUserName: '上传人',
      createTime: '上传时间',
      fztx: '封装图形',
      sqbjhx: '申请编辑回显',
      txsj: '填写数据',
      tip: '数据将永久删除, 是否继续?',
      tipName: '提示',
      delSuc: '删除成功',
      fzkxgsq: '封装库修改申请',
      fhkxgsq: '符号库修改申请',
      fzkxzsq: '封装库新增申请',
      fhkxzsq: '符号库新增申请',
      scsj: '删除数据',
      sssj: '搜索数据',
      loadDataErr: '数据加载失败！',
      selfz: '请选择封装！',
      fzkzscsq: '封装库转生产申请',
      nextTip: '下一个节点将由',
      nextTip2: '进行审批',
      nextTip3: '请指定下一节点审批人',
      opeSuc: '操作成功',
      applyComment: {
        zlhzb: '资料汇总表',
        upFile: '将文件拖到此处，或',
        clickUp: '点击上传',
        zllx: '资料类型',
        selzllx: '请选择资料类型',
        cwfk: '错误反馈',
        pl: '评论',
        bj: '笔记',
        pssm: '评审说明',
        qtxpssm: '请填写评审说明',
        fj: '附件',
        plApp: '评论及笔记评审申请',
      },
    },
    // 流程设计
    processDesign: {
      label: '流程定义及部署管理',
      placeholder: '请选择流程名称进行检索',
      name: '名称',
      version: '版本号',
      img: '流程图',
      deploymentTime: '部署时间',
      ope: '操作',
      del: '删除',
      appSet: '审批设置',
      addModel: '新建模型',
      impFile: '导入文件部署',
      impProcess: '导入流程',
      upFile: '点击上传文件',
      tip: '只允许上传".zip、.bar、.bpmn或者.bpmn20.xml"类型的文件，每次上传一个文件',
      cancel: '取 消',
      confirm: '确 定',
      inputName: '请输入流程名称',
      selType: '请选择表单类型',
      addwlsq: '新增物料申请',
      addwlkssq: '新增物料快速申请',
      wlxgsq: '物料修改申请',
      wlscsq: '物料删除申请',
      inputModel: '请填写模型信息',
      tip2: '此操作将永久删除该模型, 是否继续?',
      tipName: '提示',
      selSuc: '删除成功',
      tip3: '此操作将部署该模型, 是否继续?',
      bsSuc: '部署成功',
      lcjh: '流程激活',
      lcgq: '流程挂起',
      tip4: '此操作将永久删除该流程, 是否继续?',
      zhSuc: '转换成功',
      tip5: '上传文件只能是.zip、.bar、.bpmn或者.bpmn20.xml格式!',
      limit: '当前限制选择 1 个文件，本次选择了',
      num: '个文件，共选择了',
      fileNum: '个文件',
      confirmDel: '确定移除',
      uploadFile: '请先上传文件！',
    },
    // 运行中流程
    runningProcess: {
      fillPn: '请输入物料编码',
      fillUser: '请输入创建人',
      fillCur: '请输入当前审批人',
      processName: '流程定义名称',
      curNodeName: '当前节点名称',
      userName: '当前审批人',
      approveTime: '处理时间',
      partNumber: '物料编码',
      productModel: '名称',
      createUserName: '流程创建人',
      createTime: '流程发起时间',
      processVersion: '流程定义版本',
      isGq: '是否挂起',
      gq: '已挂起',
      wgq: '未挂起',
      ope: '操作',
      turn: '转办',
      sqsj: '申请数据',
      hxsj: '回显数据',
      sp: '审批',
      applyLibSymbolApplyPcbLib: {
        processInstanceId: '流程ID：',
        userName: '提交人：',
        updateTime: '提交日期：',
        hxsj: '回显数据',
        reUp: '重新上传',
        upList: '上传列表',
        libName: '库名称',
        changeDescription: '更改说明',
        desc: '描述',
        name: '名称',
        cName: 'package名称',
        categoryName: '分类',
        inputVersion: '录入版本号',
        version: '版本号',
        createUserName: '上传人',
        createTime: '上传时间',
        remark: '备注',
        editStatus: '编辑状态',
        ope: '操作',
        imgInfo: '图形信息',
        down: '下载',
        tm: '同名',
        glqj: '关联器件',
        onlyOld: '只在原库中存在列表',
        status: '状态',
        appHis: '审批历史',
        fztx: '封装图形',
        noData: '暂无数据',
        fzsx: '封装属性',
        hp: '焊盘：',
        dw: '单位：',
        pinNum: 'Pin数量：',
        selNode: '选择驳回节点',
        inputText: '请输入驳回意见',
        bh: '驳回',
        cancel: '取消',
        fh: '符号',
        fz: '封装',
        tip: '数据未提交，是否继续退出',
        tipName: '提示',
        noSubData: '没有提交的数据',
        limit: '文件名为空，请检查',
        next: '下一个节点将由',
        next2: '进行审批',
        next3: '请指定下一节点审批人',
        spyj: '请输入审批意见!',
        noInfo: '暂无相关信息',
        noBack: '未获取到可驳回节点',
        limit2: '请选择驳回的节点或填写驳回意见！',
        backSuc: '驳回成功',
      },
      SelectReferrer: {
        selUser: '选择转办人',
        selUser2: '请选择转办人',
        confirm: '确定交由',
        bl: '办理',
        selUser3: '请先选择转办人',
        turnSuc: '转办成功',
      },
    },
    // 已完成审批
    yetDo: {
      tab1: '元器件审批',
      tab2: '模块电路审批',
      tab3: '封装库审批',
      tab4: 'Altium符号库审批',
      tab5: 'Altium封装库审批',
      tab6: 'Capture符号库审批',
      tab7: 'Capture封装库审批',
      fzmc: '封装名称',
      createUserName: '上传人',
      status: '状态',
      ywc: '已完成',
      spz: '审批中',
      dtj: '待提交',
      packageType: '封装形式',
      processStatus: '流程状态',
      version: '原版本号',
      upTime: '上传时间',
      ope: '操作',
      fzImg: '封装图形',
      placeholder: '请输入物料编码或创建人',
      sqlx: '审批类型',
      processType: '流程名称',
      partNumber: '物料编码',
      name: '名称',
      partType: '物料类型',
      createByName: '创建人',
      createTime: '创建时间',
      wcTime: '完成时间',
      sqsj: '申请数据',
      hxsj: '回显数据',
      sp: '审批',
    },
    // 我提交的
    mySubmit: {
      tab1: '元器件审批',
      tab2: '模块电路审批',
      tab3: '封装库审批',
      tab4: 'Altium符号库审批',
      tab5: 'Altium封装库审批',
      tab6: 'Capture符号库审批',
      tab7: 'Capture封装库审批',
      processStatus: '流程状态',
      jxz: '进行中',
      zc: '正常',
      fzmc: '封装名称',
      status: '状态',
      ywc: '已完成',
      spz: '审批中',
      packageType: '封装形式',
      version: '原版本号',
      createUserName: '上传人',
      createTime: '上传时间',
      ope: '操作',
      fzImg: '封装图形',
      inputPn: '请输入物料编码',
      selStatus: '请选择状态',
      dtj: '待提交',
      sqlx: '审批类型',
      lcmc: '流程名称',
      currentNodeName: '当前执行节点名称',
      currentAssignee: '当前执行节点审批人',
      partNumber: '物料编码',
      name: '名称',
      partType: '物料类型',
      createByName: '创建人',
      createTime2: '创建时间',
      updateTime: '完成时间',
      recall: '撤回',
      sqsj: '申请数据',
      tip: '撤回流程任务会回退到个人的待办审批列表中, 是否继续?',
      tipName: '提示',
      hxsj: '回显数据',
      approve: '审批',
      symName: '符号名称',
      libName: '库名称',
      version2: '版本号',
      currentNodeName2: '当前审批节点名称',
      currentAssignee2: '当前节点审批人姓名',
      olbName: 'olb名称',
    },
    // 类目管理
    categoryManage: {
      batchImport: "批量导入",
      createAFirstLevelCategory: "创建一级类目",
      createAFirstLevelCategory: "创建一级类目",
      categorySort: "类目排序",
      placeholder: "请输入搜索内容",
      categoryManagement: "类目管理",
      detailedInformation: "详情信息",
      categoryName: "类目名称",
      categoryCode: "类目编码",
      categoryDescription: "类目描述",
      isItTheFinalCategory: "是否为末级类目",
      isItTheFinalCategoryYes: "是",
      isItTheFinalCategoryNo: "否",
      addCategory: "添加类目",
      saveChanges: "保存修改",
      delete: "删除",
      addCategoryDialog: {
        title: "添加类目",
        categoryName: "类目名称",
        categoryNameRule: "类目名称不能为空",
        categoryCode: "类目编码",
        categoryCodeRule: "类目编码不能为空",
        categoryDescription: "类目描述",
        diagram: "示意图",
        selectSchematic: "选择示意图",
        uploadPngTips: "只能上传png文件",
        cancel: "取消",
        confirm: "确定",
      },
      selectImgDialog: {
        title: "选择图片",
        categoryPictures: "类目图片",
        devicePictures: "器件图片",
      },
      attributeInformation: "属性信息",
      newProperties: "新增属性",
      preserveAttributeOrder: "保存属性顺序",
      batchDeletion: "批量删除",
      attributes: "属性",
      displayName: "显示名称",
      isComparisonFields: "是否为比较字段",
      idSearchFields: "是否为搜索字段",
      templateName: "模板名称",
      isToBringInDesign: "是否带入设计",
      designName: "设计的名称",
      operate: "操作",
      attributesUp: "上移",
      attributesDown: "下移",
      attributesEdit: "修改",
      selectTemplateDialog: {
        title: "选择模板",
        attributes: "属性",
        attributesPlaceholder: "请选择属性",
        selectTemplateTips: "注：蓝色背景为已存在属性",
        attributesName: "属性名称",
        attributesCode: "属性编号",
        displayName: "显示名称",
        englishName: "英文名称",
        category: "所属类别",
        typeOfData: "数据类型",
        isComparisonFields: "是否比较字段",
        idSearchFields: "是否搜索字段",
        isToBringInDesign: "是否带入设计",
        designName: "设计的名称",
        remark: "备注",
        operate: "操作",
      },
      categorySortDialog: {
        title: "类目排序",
      },
      batchImportDialog: {
        title: "批量导入",
        text1: "将文件拖到此处，或点击上传",
        text2: "只能上传.xlsx文件",
        downloadTemplate: "请下载导入模板"
      },
      editDialog: {
        title: "修改",
        idSearchFields: "是否为搜索字段",
        isToBringInDesign: "是否带入设计",
        designName: "设计的名称",
        cancel: "取消",
        confirm: "确定",
      },
      methods: {
        deviceTips1: "不同种类的器件不可以排序",
        deviceTips2: "相同种类的器件不可以嵌套排序",
        idClose: "确认关闭？",
        pngTips: "请上传正确格式png",
        categoryNameTips: "请输入类目名称",
        categoryCodeTips: "请输入类目编码",
        delTips: "此操作将永久删除该文件, 是否继续?",
        hint: "提示",
        cancel: "取消",
        confirm: "确定",
        designNameTips: "请输入设计的名称",
        delAttributesTips: "此操作将删除该属性, 是否继续?",
      }
    },
    // 属性模板管理
    attributeTemp: {
      attributeManagement: "属性管理",
      addAttribute: "添加属性",
      batchDeletion: "批量删除",
      loading: "拼命加载中",
      propertyName: "属性名称",
      propertyNumber: "属性编号",
      displayName: "显示名称",
      englishName: "英文名称",
      category: "所属类别",
      typeOfData: "数据类型",
      remark: "备注",
      comparisonFields: "比较字段",
      isToBringInDesign: "是否带入设计",
      designName: "设计的名称",
      operate: "操作",
      edit: "编辑",
      delete: "删除",
      up: "上移",
      down: "下移",
      templateManagement: "模板管理",
      addTemplate: "添加模板",
      copyTemplate: "复制模板",
      editTemplate: "修改模板",
      deleteTemplate: "删除模板",
      name: "名称",
      remark: "备注",
      addDialog: {
        title1: "添加模板",
        title2: "添加属性",
        name: "名称",
        number: "属性编号",
        showName: "显示名称",
        englishName: "英文名称",
        category: "所属类别",
        categoryPlaceholder: "请选择数据类型",
        dataType: "所属类别",
        dataTypePlaceholder: "请选择所属类别",
        remark: "备注",
        comparisonFields: "比较字段",
        isToBringInDesign: "是否带入设计",
        designName: "设计的名称",
        cancel: "取消",
        confirm: "确定",
        copyTemplateDialog: {
          title: "复制模板",
          confirm: "确定",
          placeholder: "请输入新模板的名字",
        },
        methods: {
          addTips: "请点击左侧的分组中的属性分类再点击添加属性",
          nameEmptyTips: "名称未填写",
          duplicateNameTips: "名称重复",
          delTips: "此操作将永久删除该属性, 是否继续?",
          hint: "提示",
          cancel: "取消",
          confirm: "确定",
          calcleDelTips: "已取消删除",
          delTemplateTips: "此操作将永久删除该模板, 是否继续?",
          designNameEmptyTips: "请输入设计的名称",
          nameEmptyTips2: "请先填写名称",
        }
      }
    },
    // 字段配置
    fieldRechecking: {
      propertyType: "属性类型",
      defaultProperties: "默认属性",
      functionalProperties: "功能属性",
      extendedProperties: "扩展属性",
      fieldName: "字段名称",
      displayName: "显示名称",
      save: "保存",
      loading: "拼命加载中",
      index: "序号",
      controlType: "控件类型",
      input: "输入框",
      DropDownSelection: "下拉选",
      pullDownMultipleSelection: "下拉多选",
      pullDownInput: "下拉输入",
      img: "图片",
      systemGenerated: "系统生成",
      controlValueRestrictions: "控件值限定",
      defaultValue: "默认值",
      promptText: "提示文字",
      listDisplay: "列表展示",
      detailedDisplay: "详情展示",
      similarFields: "相似字段",
      checkDuplicateFields: "查重字段",
      quickSearchField: "快速搜索字段",
      advancedSearchFields: "高级搜索字段",
      filterFields: "筛选字段",
      requiredField: "必填字段",
      isShow: "是否展示",
      bringInCapture: "带入Capture",
      sort: "排序",
      moveRows: "移动行数",
      addValueDialog: {
        title: "添加值",
        save: "保存",
        placeholder: "请输入值"
      },
      methods: {
        sortTips: "排序位置超出数据总数",
        displayNameTips: "请填写 { field } 的显示名称",
        inputValueTips: "请输入值"
      }
    },
    // 类目示意图
    categoryPicture: {
      uploadText: "将文件拖到此处，或点击上传",
      uploadLimitTips: "只能上传jpg/png文件",
      tab1: "类目图片",
      del: "删除",
      tab2: "器件图片",
      uploadFormatTips: "请上传正确格式文件",
    },
    // 审批模板
    approveTemplate: {
      del: "点击删除",
      add: "点击创建模板",
      fieldName: "字段名称",
      zhName: "中文名",
      isEdit: "是否可编辑",
      isRequired: "是否必填",
      isShow: "是否展示",
      cancel: "取消",
      confirm: "确定",
      addTemplateDialog: {
        title: "创建模板",
        templateName: "模板名称",
        placeholder: "请输入模板名称",
        fieldName: "字段名称",
        zhName: "中文名",
        isEdit: "是否可编辑",
        isRequired: "是否必填",
        isShow: "是否展示",
        cancel: "取消",
        confirm: "创建",
      },
      methods: {
        templateNameTips: "请输入模板名称",
        templateNameTips2: "模板名称不能设置为基础数据",
        templateNameNotRepeatTips: "模板名称不能重复",
        timeFormat: "--年--月--日",
        just: "刚刚",
        minutesAgo: "分钟前",
        today: "今天",
        yesterday: "昨天",
        month: "月",
        year: "年",
        day: "日",
      }
    },
    // 编码规则
    codePage: {
      classificationCoding: "分类编码",
      customEncoding: "自定义编码",
      generateEncodingDialog: {
        title: "生成编码",
        placeholder: "请输入生成数量",
        generate: "生成",
      },
      paragraph: "第{ count }段",
      placeholder1: "请选择",
      placeholder2: "请输入字符",
      placeholder3: "不能为字母数字汉字",
      placeholder4: "请输入位数",
      placeholder5: "请输入字母",
      placeholder4: "请输入位数",
      add: "添加",
      del: "删除",
      view: "编码预览",
      application: "应用",
      generatedCodeDialog: {
        title: "已生成的编码",
        code: "编码",
        createTime: "创建时间",
      },
      methods: {
        NotdecimalTips: "该数值需为整数,不能为小数",
        greaterThanZeroTips: "该数值需为大于零的正数",
        typeOptions1: "自定义",
        typeOptions2: "分隔符",
        typeOptions3: "日期",
        typeOptions4: "流水号",
        typeOptions5: "分类",
        canEnter: "可以输入",
        categoryRequiredTips: "分类必选",
        valueEmptyTips: "有值为空，请检查",
        serialNumberInvalidTips: "流水号不合法，请检查",
        repeatSerialNumberTips: "请选择个流水号并确保不重复",
      }
    },
    // 制造商
    manufacturerList: {
      placeholder: "请输入新增的分类名称",
      cabcle: "取消",
      confirm: "确定",
      addFirstLevelClass: "新增一级分类",
      addClass: "添加分类",
      editClass: "修改分类",
      delClass: "删除分类",
      namePlaceholder: "名称",
      new: "新建",
      addManufacturer: "添加制造商",
      addBatches: "批量添加",
      addItemsBulk: "批量添加物料",
      updateItemsBulk: "批量更新物料",
      loading: "拼命加载中",
      name: "公司名称",
      abbreviation: "公司简称",
      website: "网址",
      phone: "联系方式",
      preRanking: "优选等级",
      differentCountries: "地址",
      fax: "传真",
      email: "邮箱",
      oldName: "历史曾用名",
      qualification: "厂家资质",
      intro: "公司简介",
      remark: "备注",
      compdrawComp: {
        tab1: "制造商",
        tab2: "物料",
        name: "公司名称",
        abbreviation: "公司简称",
        category: "分类",
        differentCountries: "地址",
        website: "网址",
        phone: "联系方式",
        fax: "传真",
        email: "邮箱",
        preRanking: "优选等级",
        oldName: "历史曾用名",
        add: "添加",
        qualification: "厂家资质",
        intro: "公司简介",
        remark: "备注",
        del: "删除",
        confirm: "确定",
      },
      mateListComp: {
        placeholder: "物料名称/编号",
        loading: "拼命加载中",
        componentName: "物料名称",
        manufacturersNo: "制造商物料编码",
        remark: "物料描述",
        operate: "操作",
        edit: "编辑",
        del: "删除",
        materialDialog: {
          title: "物料",
          manufacturersId: "制造商",
          placeholder: "请选择",
          componentName: "物料名称",
          manufacturersNo: "制造商物料编码",
          remark: "物料描述",
          calcle: "取消",
          confirm: "确定",
        },
        supplierDialog: {
          title: "供应商",
          confirm: "确认选择",
        },
        batchImportTempStep: "操作步骤",
        batchImportTempStep1: "下载《批量导入模板》",
        batchImportTempStep2: "立即下载《批量导入模板》",
        batchImportTempStep3: "根据模板整理相应数据，为保障粘贴信息被有效导入，请使用纯文本或数字",
        batchImportTempStep4: "信息输入完毕，将文件拖入下方方框中，或者点击选取",
        batchImportTempStep5: "预览检查数据(如有问题则会标识出来,敬请改正)",
        batchImportTempStep6: "确认导入",
        uploadText: "将文件拖到此处，或点击上传",
        batchUpdateTempStep: "下载《批量跟新模板》",
        batchUpdateTempStep1: "立即下载《批量修改模板》",
        batchUpdateTempStep2: "根据模板整理相应数据，为保障粘贴信息被有效导入，请使用纯文本或数字",
        batchUpdateTempStep3: "信息输入完毕，将文件拖入下方方框中，或者点击选取",
        batchUpdateTempStep4: "预览检查数据(如有问题则会标识出来,敬请改正)",
        batchUpdateTempStep5: "确认导入",
        importDialog: {
          title: "导入",
          save: "保存",
          manufacturersName: "制造商",
          manufacturersNo: "制造商物料编码",
          componentName: "物料名称",
          componentState: "生产状态",
          logTime: "更新时间",
        },
        methods: {
          repeatTips: "行名为 {name} 重复,",
          notExist: "制造商{ name } 不存在",
          row: "行",
          materialNameTips: "请输入物料名称",
          materialCodingTips: "请输入物料编码",
          modifyQuestionTips: "请修改问题数据后再导入",
          importSuccessfulTips: "导入成功",
          hint: "提示",
          cancel: "取消",
          confirm: "确定",
          newProducts: "新产品",
          massProduction: "批量生产",
          notRecommended: "不推荐用于新设计",
          discontinued: "停产",
          oldProducts: "老产品",
        }
      },
      addCateDialog: {
        title: "添加分类",
        placeholder: "请输入分类名称",
        cancel: "取消",
        confirm: "确定",
      },
      editCateDialog: {
        title: "修改分类",
        placeholder: "请输入分类名称",
        cancel: "取消",
        confirm: "确定",
      },
      methods: {
        companyNameTips: "请输入公司名称",
        abbreviationTips: "请输入公司简称",
        chartTips: "长度在 7 到 200 个字符",
        imgTips: "请粘贴或选择图片",
        categoryNameTips: "请输入分类名称",
        delTips: "此节点有子级，不可删除！",
        delTips2: "是否删除此节点？",
        hint: "提示",
        confirm: "确认",
        cancel: "取消",
        supportTips: "最多只支持两级！",
        classifyTips: "请先选择分类",
        nameEmptyTips: "名称不能为空",
        rowTips: "第{ row }行{ name } ,",
        repeatTips: "第{ row1 ]行和第[ row2 ]行重复 ,",
        existTips: "第[ row ]行{ name }已存在 ,",
        dataWrongTips: "导入数据有误",
        categoryNameTips2: "请输入新增的分类名称"
      }
    },
    // 供应商
    supplierList: {
      placeholder: "请输入新增的分类名称",
      cabcle: "取消",
      confirm: "确定",
      addFirstLevelClass: "新增一级分类",
      addClass: "添加分类",
      editClass: "修改分类",
      delClass: "删除分类",
      namePlaceholder: "名称",
      new: "新建",
      addSupplier: "添加供应商",
      addBatches: "批量添加",
      addItemsBulk: "批量添加物料",
      updatePriceBulk: "批量更新价格",
      loading: "拼命加载中",
      name: "公司名称",
      abbreviation: "公司简称",
      website: "网址",
      phone: "联系方式",
      preRanking: "优选等级",
      differentCountries: "地址",
      fax: "传真",
      email: "邮箱",
      oldName: "历史曾用名",
      qualification: "厂家资质",
      intro: "公司简介",
      remark: "备注",
      compdrawComp: {
        tab1: "供应商",
        tab2: "物料",
        name: "公司名称",
        abbreviation: "公司简称",
        category: "分类",
        differentCountries: "地址",
        website: "网址",
        phone: "联系方式",
        fax: "传真",
        email: "邮箱",
        preRanking: "优选等级",
        oldName: "历史曾用名",
        add: "添加",
        qualification: "厂家资质",
        intro: "公司简介",
        remark: "备注",
        del: "删除",
        confirm: "确定",
      },
      mateListComp: {
        numberNamePlaceholder: "供应商物料编码",
        loading: "拼命加载中",
        suppliersName: "供应商",
        suppliersNo: "供应商物料编码",
        manufacturersName: "制造商",
        manufacturersNo: "制造商物料编码",
        suppliersMoq: "最小订单量",
        suppliersStockCycle: "供货周期",
        remark: "备注",
        suppliersMoq: "最小订单量",
        operate: "操作",
        edit: "编辑",
        del: "删除",
        batchImportTempStep: "操作步骤",
        batchImportTempStep1: "下载《批量导入模板》",
        batchImportTempStep2: "立即下载《批量导入模板》",
        batchImportTempStep3: "根据模板整理相应数据，为保障粘贴信息被有效导入，请使用纯文本或数字",
        batchImportTempStep4: "信息输入完毕，将文件拖入下方方框中，或者点击选取",
        batchImportTempStep5: "预览检查数据(如有问题则会标识出来,敬请改正)",
        batchImportTempStep6: "确认导入",
        uploadText: "将文件拖到此处，或点击上传",
        batchUpdateTempStep: "下载《批量跟新模板》",
        batchUpdateTempStep1: "立即下载《批量修改模板》",
        batchUpdateTempStep2: "根据模板整理相应数据，为保障粘贴信息被有效导入，请使用纯文本或数字",
        batchUpdateTempStep3: "信息输入完毕，将文件拖入下方方框中，或者点击选取",
        batchUpdateTempStep4: "预览检查数据(如有问题则会标识出来,敬请改正)",
        batchUpdateTempStep5: "确认导入",
        placeholder: "请选择",
        noMaterial: "该制造商下暂无物料！",
        fillInformation: "信息填写",
        suppliersNo: "供应商物料编码",
        suppliersMoq: "最小订单量",
        suppliersStockCycle: "备货周期",
        remark: "备注",
        importDialog: {
          title: "导入",
          save: "保存",
          manufacturersName: "制造商",
          manufacturersNo: "制造商物料编码",
          suppliersNo: "供应商物料编码",
          suppliersMoq: "最小订单量",
          suppliersStockCycle: "供货周期",
        },
        updatedPricesDialog: {
          suppliersName: "供应商",
          manufacturersName: "制造商",
          manufacturersNo: "制造商物料编码",
          suppliersNo: "供应商物料编码",
          priceLogTime: "更新时间",
          suppliersStock: "库存",
        },
        methods: {
          required: "必填",
          abbreviationTips: "请输入公司简称",
          repeatTips: "行名为{ name }重复,",
          notExist1: "制造商{ name } 不存在",
          notExist2: "供应商{ name } 不存在",
          row: "行",
          modifyQuestionTips: "请修改问题数据后再导入",
          hint: "提示",
          notNegative: "最小订单量不能为负数",
        }
      },
      addCateDialog: {
        title: "添加分类",
        placeholder: "请输入分类名称",
        cancel: "取消",
        confirm: "确定",
      },
      editCateDialog: {
        title: "修改分类",
        placeholder: "请输入分类名称",
        cancel: "取消",
        confirm: "确定",
      },
      methods: {
        companyNameTips: "请输入公司名称",
        abbreviationTips: "请输入公司简称",
        chartTips: "长度在 7 到 200 个字符",
        imgTips: "请粘贴或选择图片",
        categoryNameTips: "请输入分类名称",
        delTips: "此节点有子级，不可删除！",
        delTips2: "是否删除此节点？",
        hint: "提示",
        supportTips: "最多只支持两级！",
        classifyTips: "请先选择分类",
        nameEmptyTips: "名称不能为空",
        rowTips: "第{ row }行{ name } ,",
        repeatTips: "第{ row1 ]行和第[ row2 ]行重复 ,",
        existTips: "第[ row ]行{ name }已存在 ,",
        dataWrongTips: "导入数据有误",
        categoryNameTips2: "请输入新增的分类名称"
      }
    },
    // Capture符号库
    symbolFormList: {},
    // Concept符号库
    symbolicLibrary: {},
    // Allegro封装库
    packageFileUpload: {},
    // Altium符号库
    altiumsymbolList: {},
    // Altium封装库
    altiumEncapsulationList: {},
    // 三维库
    threeDimensionalLib: {},
    // 设计审查
    dataReview: {
      ypz: '已配置',
      tool: '版本工具',
      noTool: '暂未配置审查工具，点击配置',
      kssc: '快速审查',
      bomsc: 'BOM审查',
      zdybom: '自定义BOM',
      mxbsc23: '23所明细表输出',
      mxbsc: '明细表输出',
      xcmxbsc: '欣创明细表输出',
      scexcelpdf: '输出元器件表Excel、PDF',
      saveRes: '保存审查结果',
      impRes: '导入审查结果',
      exportCheckRes: '输出设计审查报告',
      lookInfo: '查看详情',
      selField: '请选择搜索字段',
      inputCon: '请输入搜索内容',
      searchData: '搜索审查数据',
      ss: '搜索',
      clearss: '清空搜索',
      plthsx: '批量替换属性',
      plthqj: '批量替换器件',
      sjmc: '设计名称：',
      qbzt: '全部状态',
      tgsc: '通过审查',
      nopn: '没有物料编码',
      noData: '数据库无对应数据',
      refwt: '位号问题',
      bupp: '不匹配',
      yxjb: '优选级别',
      jyl: '禁用料',
      lsl: '临时料',
      wlzt: '物料状态',
      dzl: '呆滞料',
      tcj: '不插件',
      yddc: '有顶底层',
      zxth: '执行替换',
      calcel: '取 消',
      sczt: '审查状态',
      ope: '操作',
      inDes: '在设计中选中',
      more: '更多',
      ksth: '快速替换',
      sx: '属性',
      qj: '器件',
      ksplth: '快速批量替换',
      sc: '审查',
      plsc: '批量审查',
      jzyssj: '加载原始数据',
      scyl: '手册预览',
      tjktdl: '添加可替代料',
      plthktdl: '批量替换可替代料',
      scsj: '审查数据',
      yltsx: '原理图属性',
      cmssx: 'CMS属性',
      noComData: '暂无审查对比数据！',
      ktdl: '可替代料',
      up: '上移',
      down: '下移',
      del: '删除',
      noReplace: '暂无可替代料！',
      importCapture: '导入Capture属性文件',
      clickUp: '点击上传文件',
      captureSym: 'capture原理图符号',
      fzfh: '封装符号',
      tjktdwl: '添加可替代物料',
      save: '保存',
      partlist: '元器件库列表',
      sel: '请选择',
      replace: '替换',
      byValue: '按Value替换',
      byPn: '按partNumber替换',
      tip: '一键替换全部属性请点击"一键替换全部"按钮',
      tip2: '选择部分属性请勾选属性后点击"替换"按钮',
      plth: '批量替换',
      thplAll: '一键批量替换全部',
      thAll: '一键替换全部',
      tj: '推荐',
      sxgx: '属性更新',
      tip3: '一键更新全部属性请点击"一键更新全部"按钮',
      updateAll: '一键更新全部',
      tip4: '选择部分属性请勾选属性后点击"更新"按钮',
      update: '更新',
      bcpz: '保存配置',
      scbom: '输出BOM',
      allSel: '全选',
      qxkxsx: '全选（可选属性）',
      qxdcsx: '全选（导出属性）',
      scxs: '输出形式：',
      byPnExp: '按PN输出',
      byRefExp: '按Ref输出',
      cfref: '拆分位号',
      whfgf: '位号分隔符',
      selfgf: '请选择分隔符',
      hbwhljf: '合并位号连接符',
      selljf: '请选择连接符',
      isExprep: '是否导出替代料',
      isExpfy: '是否导出复用模块',
      inputmxb: '请输入明细表名称',
      expbom1: '输出BOM(有规格型号)',
      expbom2: '输出BOM(无规格型号)',
      filetype: '文件格式:',
      zblh: '主板料号：',
      zbmc: '主板名称：',
      zt: '状态：',
      yjbb: '硬件版本：',
      bc: '版次：',
      yzbh: '印制板号：',
      bjth: '板级图号：',
      confirmExp: '确定输出',
      pleaseSel: '明细表输出完成，请选择',
      openList: '打开明细表',
      openDir: '打开目录',
      cancel: '取消',
      pleaseSel2: '自定义BOM输出完成，请选择',
      openBom: '打开自定义BOM',
      upbom: '上传BOM',
      uptip: '将文件拖到此处，或',
      uptip2: '点击上传',
      uptip3: '只能上传excel文件',
      imp: '导入',
      impTip: '只能导入json文件',
      bzbommodel: '变种BOM模式',
      listTitle: '原理图BOM表——格式A',
      cms: '电子元器件',
      cbb: '模块电路',
      kg: '空格',
      isExitbzbom: '是否退出变种BOM模式?',
      tipName: '提示',
      inbzbom: '确认审查数据无误，进入此模式无法进行设计替换功能, 是否继续?',
      exitbzbom: '退出变种BOM模式',
      setTool: '请先配置审查工具',
      upFileErr: '上传文件有误，请检查!',
      buzc: '不支持Deehow CMS Pro',
      noscData: '没有审查数据无法输出明细表！',
      bomlist: 'BOM明细表输出',
      noCheckData: '没有审查数据无法输出元器件表Excel、PDF！',
      chackRes: '_审查结果',
      dyErr: '调用命令错误:',
      limitjson: '上传文件只能是json格式!',
      fileErr: '文件处理错误，请检查文件格式是否正确',
      inputth: '请输入板级图号',
      inputyzb: '请输入印制板号',
      confirmTip: '请确认当前审查的是最新设计，否则请点击【快速审查】获取最新数据。',
      bomName: 'BOM名称',
      BOMNo: '产品代号',
      codename: '代号',
      cmslist: '_元器件表',
      mxberr: '明细表输出失败，请稍后再试',
      ref: '位号',
      pn: '物料编号',
      goSet: '去配置页面',
      notSet: '暂不配置',
      sepLimit: '分隔符和连接符不能一样',
      saveSuc: '保存成功！',
      platformField: '(原理图字段)',
      expLimit: '没有审查数据无法输出BOM！',
      expTip: '请确认当前审查的是最新设计，否则请点击【快速审查】获取最新数据。',
      saveSet: '请先保存配置！',
      expBomSuc: '导出BOM成功',
      expBomErr: '导出BOM失败，请稍后再试！',
      capture: "Capture原理图符号",
      adSym: 'Altium符号',
      adFoot: 'Altium封装',
      cadenceFoot: 'Cadence封装',
      addRepTip: '请先选择审查数据！',
      addRepTip2: '已添加此物料！',
      replaceTip: '所选审查数据无物料编码，其他无物料编码的元器件都使用此可替代料，是否继续替换？',
      replaceTip2: '可替代料的Value或封装与所选审查数据不一致，是否继续替换？',
      replaceTip3: '可替代料未匹配原理图符号（或封装，或原理图符号和封装）是否继续替换？',
      replaceTip4: '该替换的器件没有Capture原理图符号或PCB封装, 是否继续替换?',
      noDatath: '没有审查数据，无法执行替换操作！',
      pre: '预览',
      noDataBook: '该手册不存在！',
      selCheckData: '请先勾选批量审查数据',
      allschdoc: '全部SchDoc',
      allpage: '全部page',
      selData: '请先选择数据',
      repTip: '替换完成后，请再次执行“快速审查”重新审查数据',
      noRepData: '没有可替换的数据',
      cancelRep: '已取消替换',
      repTip2: '替换物料的Value、Pcbfootprint和审查数据不一致, 是否继续替换?',
      selRepPart: '请选择要替换的器件',
      stopUse: '此物料是禁用料，禁止调用！',
      useTip: '此物料是临时料，是否继续?',
      repTip3: '替换物料的Value、Pcbfootprint和审查数据不一致, 是否继续批量替换?',
      selRepPart2: '请先选择替换器件！',
      upLimit: '当前限制选择1个文件，本次选择了',
      upLimit2: '个文件，共选择了',
      upLimit3: '个文件',
      upFileTip: '清先上传文件',
      canNotRep: '所有位号的元器件都没有物料编码或数据库无对应数据，无法替换。',
      noRefTip: '的元器件没有物料编码或数据库无对应数据，无法替换。是否继续执行其他元器件替换？',
      addReplace: {
        advSearch: '高级搜索',
        dy: '等于',
        bdy: '不等于',
        day: '大于',
        xyu: '小于',
        dydy: '大于等于',
        xydy: '小于等于',
        bh: '包含',
        input: '输入',
        addCond: '添加搜索条件',
        cancel: '取消',
        search: '搜索',
        placeholder: '快速搜索',
        please: '请按',
        attrSearch: '属性搜索',
        name: '名称',
        clear: '清空',
        gjss: '高级搜索',
        hasCond: '高级搜索弹窗中，已经输入了检索条件!',
        selCate: '当前选中分类：',
        clearCate: '清除当前所选分类',
        cn: '中文名称',
        nothing: '什么都没搜到',
      },
      newTransfer: {
        inputSearch: '请输入搜索内容',
      },
      showSvg: {
        name: '名称',
        pcbTitle: '这是PCB封装预览，如果有多个封装的话，显示第一个。',
        noData: '暂无数据',
        captureTitle: '这是Capture原理图的web预览，如果有多个原理图的话，仅显示第一个。',
        conceptShow: '这是Concept原理图的web预览，如果有多个原理图的话，仅显示第一个。',
      },
      audit: {
        loadText: '程序奔跑中',
        newGroup: '新建分组',
        copy: '复制',
        editName: '修改名称',
        del: '删除',
        export: '导出',
        sczt: '审查状态',
        wlbh: '物料编号',
        ref: '位号',
        bcj: '不插件',
        foot: 'Cadence封装符号',
        sym: 'Capture原理图符号',
        yxdj: '优选等级',
        smzq: '生命周期',
        ppxx: '品牌信息',
        rohs: 'rohs符合性',
        cn: '中文名称',
        zwxh: '中文型号',
        ds: 'ds路径',
        xybtj: '限用不推荐说明',
        ab: 'ab配套',
        ope: '操作',
        selDes: '在设计中选中',
        more: '更多',
        lookLib: '查看库数据',
        pubCms: '公用元器件',
        setbcj: '批量设为不插件',
        cancelbcj: '批量取消不插件',
        replaceLib: '库替换',
        remove: '移除',
        check: '审查',
        mulCheck: '批量审查',
        qbzt: '全部状态',
        tgsc: '通过审查',
        nopn: '没有物料编码',
        dbNoData: '数据库无对应数据',
        noRule: '不匹配',
        yxjb: '优选级别',
        jyl: '禁用料',
        lsl: '临时料',
        yddc: '有顶底层',
        confirm: '确定',
        sel: '选择工程',
        selTip: '请选择工程',
        sel2: '选择设计文件',
        selTip2: '请选择设计文件',
        addGroup: '添加分组',
        groupName: '组名称',
        editGroup: '修改分组',
        copyGroup: '复制分组',
        parts: '元器件库列表',
        pleaseSel: '请选择',
        replace: '替换',
        byValueReplace: '按Value替换',
        byPnReplace: '按partNumber替换',
        ksth: '快速替换',
        repTip: '一键替换全部属性请点击"一键替换全部"按钮',
        repMulMore: '一键批量替换全部',
        repMore: '一键替换全部',
        selAttrTip: '选择部分属性请勾选属性后点击"替换"按钮',
        mulRep: '批量替换',
        inputListName: '请输入明细表名称',
        confirmExp: '确定输出',
        zblh: '主板料号',
        zbmc: '主板名称',
        status: '状态',
        yjbb: '硬件版本',
        bc: '版次',
        sing: '单个',
        mul: '批量',
        attr: '属性',
        byValue: '按Value',
        byPn: '按PartNumber',
        searchTip: '搜索项不能为空.',
        expTip: '没有数据无法输出明细表！',
        filename: '原理图BOM表——格式A',
        tip: '复用模块,典型电路不支持替换',
        dsnPath: 'dsn文件路径不对',
        desFile: '设计文件',
        partGroup: '元器件分组',
        vitBom: '变种bom',
        moveTip: '该节点下的器件不能进行拖拽！',
        moveTip2: '不能移动到末级节点!',
        moveSuc: '拖拽成功',
        addSuc: '添加成功',
        editSuc: '修改成功',
        copySuc: '复制成功',
        delSuc: '删除成功',
        getErr: '设计id获取失败，请重新获取',
        tipName: '提示',
      },
      transferPanel: {
        noppData: '无匹配数据',
        noData: '无数据',
      }
    },
    // 审查配置
    reviewSet: {
      ypz: '已配置',
      tool: '版本工具',
      noTool: '暂未配置审查工具，点击配置',
      reset: '恢复默认设置',
      save: '保存',
      cxxg: '撤销修改',
      syncAll: '同步所有用户',
      syncPart: '只同步尚未设置的用户',
      zdsz: '审查字段设置',
      isCheck: '是否审查',
      fieldName: '字段名称',
      ref: '位号',
      select: '请选择',
      showName: '显示名称',
      platformField: '原理图字段',
      isTableShow: '审查列表字段',
      isDetailShow: '详情展示',
      isReplaceShow: '可替代料展示',
      ope: '操作',
      up: '上移',
      down: '下移',
      del: '删除',
      addField: '添加审查字段',
      gnset: '审查功能项设置',
      jyl: '禁用料',
      lsl: '临时料',
      dzl: '呆滞料',
      refwt: '位号问题',
      tip: '审查原理图中的位号问题：空值、重复、问号',
      scyxjb: '审查优选级别',
      yxjgy: '优选级别高于：',
      yxjdy: '优选级别低于：',
      scbcj: '审查不插件',
      bcjzd: '不插件标识字段：',
      inputbs: '请输入标识',
      bcjqz: '不插件前缀',
      bcjhz: '不插件后缀',
      scwlzt: '审查物料状态',
      wlztzd: '物料状态字段',
      selzd: '请选择物料状态字段',
      bfhztbs: '不符合状态标识',
      tip2: '请确认已保存当前设置',
      topName: '提示',
      yqjsxz: '元器件属性值',
      limit: '字段名称和原理图字段至少填写一个！',
      limit2: '优选级别不能为空',
      limit3: '对应优选级别不能为空',
      limit4: '不插件标识或前缀后缀不能为空',
      limit5: '物料状态字段或不符合状态标识不能为空',
      saveSet: '保存配置成功',

    },
    // BOM自检
    bomSelfCheck: {
      upBom: '上传BOM',
      name: '设计名称：',
      checkStatus: '审查状态',
      remark: '备注',
      tip: '将文件拖到此处，或',
      tip2: '点击上传',
      tip3: '只能上传excel文件',
      qbzt: '全部状态',
      tgsc: '通过审查',
      mywlbm: '没有物料编码',
      sjkwdysj: '数据库无对应数据',
      bpp: '不匹配',
      yxjb: '优选级别',
      wlzt: '物料状态',
      whcf: '位号重复',
      byz: '位号-数量不一致',
      qspcb: '缺少PCB',
      gyh: '归一化',
      wlbh: '物料编号',
      dh: '代号',
      fz: '封装',
      sl: '数量',
      bm: '板面',
      wlms: '物料描述',
      limit: '请选择文件',
      limit2: '只支持Excel文件',
    },
    // BOM互检
    bomMutualCheck: {
      upBom1: '上传bom1',
      upBom2: '上传bom2',
      btn1: '按PartReference比较bom',
      btn2: '按Part_Number比较bom',
      comRes: '比较结果',
      zh: '注：',
      tip1: 'BOM1存在BOM2不存在的数据，',
      tip2: 'BOM1不存在BOM2存在的数据，',
      tip3: '属性不同的数据',
      upBom: '上传bom文件',
      save: '保 存',
      upFile: '点击上传文件',
      limit: '只允许上传".xlsx .json"类型的文件，最多可上传1个文件',
      bomDig: 'bom 对比框',
      limit2: '上传文件只能是xlsx格式或json格式!',
    },
    // BOM编辑
    bomEdit: {
      impBom: '导入BOM',
      exportBom: '导出BOM',
      ope: '操作',
      addPart: '新增元器件',
      addRep: '新增可替代料',
      del: '删除',
      save: '保 存',
      save2: '保存',
      upFile: '点击上传文件',
      limit: '只允许上传".xlsx"类型的文件，最多可上传1个文件',
      pn: '物料编号',
      desc: '物料描述',
      cj: '层级',
      index: '序号',
      dh: '代号',
      num: '数量',
      fz: '封装',
      bm: '板面',
      bz: '备注',
      bz1: '备注1',
      bz2: '备注2',
      gg: '更改',
      tip: '请先导入BOM',
      tip2: '上传文件只能是xlsx格式!',
      tip3: '请选择要替换的器件',
    },
    // 产品设计管理
    ProductManage: {},
    // 元器件统计
    ComponentStatistics: {},
    // 板卡BOM
    bomManagement: {},
    // BOM模板配置
    bomTemplateConfig: {},
    // 质量信息库
    info: {},
    // 统计分析
    stat: {},
    // 全部消息
    allMessage: {},
    // 未读消息
    unreadMessage: {},
    // 已读消息
    readMessage: {},
    // 员工管理
    employeesManage: {},
    // 角色管理
    webRolePermiss: {},
    // 系统设置
    systemSetting: {},
    // 集成管理
    integrationManagement: {},
    // BOM比较
    bomCompare: {},
    // 操作日志
    operateLogs: {},
    // 登录日志
    loginLogs: {},
    // 模块电路-文件库
    filesList: {},
    // 模块电路-Altium模型库文件
    altiumFiles: {},
    404: {
      content: {
        text1: "请检查您输入的网址是否正确，请点击以下按钮返回",
        text2: "主页或者发送错误报告",
        text3: "暂无进入该页面的权限或该页面不存在！",
        backHomeBtn: "返回首页",
      }
    }
  }
}
