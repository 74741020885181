<template>
  <el-dialog :title="$t('navbar.modal.editPasswordModal.title')" :close-on-click-modal="false" v-dialogDrag :visible.sync="dialogSetPassword" size="tiny"
             :modal="false" @close="handleSetPasswordModalClose">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item :label="$t('navbar.modal.editPasswordModal.content.form.originalPassword')" :label-width="formLabelWidth" prop="oldPassword">
        <el-input :type="isShowOld ? 'password' : 'text'" :placeholder="this.$t('navbar.modal.editPasswordModal.content.formRules.originalPassword')" v-model="form.oldPassword"
                  auto-complete="off">
          <template slot="suffix">
            <img style="width: 15px;" @click="isShowOld = !isShowOld" alt=""
                 :src="isShowOld ? require('../../assets/img/EyeInvisible.png') : require('../../assets/img/mimakejian.png')"/>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('navbar.modal.editPasswordModal.content.form.newPassword')" :label-width="formLabelWidth" prop="password">
        <el-input :type="isShowNew ? 'password' : 'text'" :placeholder="this.$t('navbar.modal.editPasswordModal.content.formRules.newPassword')" v-model="form.password"
                  auto-complete="off">
          <template slot="suffix">
            <img style="width: 15px;" @click="isShowNew = !isShowNew" alt=""
                 :src="isShowNew ? require('../../assets/img/EyeInvisible.png') : require('../../assets/img/mimakejian.png')"/>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('navbar.modal.editPasswordModal.content.form.confirmPassword')" :label-width="formLabelWidth" prop="pass1">
        <el-input :type="isShowCon ? 'password' : 'text'" :placeholder="this.$t('navbar.modal.editPasswordModal.content.formRules.confirmPassword2')" v-model="form.pass1" auto-complete="off">
          <template slot="suffix">
            <img style="width: 15px;" @click="isShowCon = !isShowCon" alt=""
                 :src="isShowCon ? require('../../assets/img/EyeInvisible.png') : require('../../assets/img/mimakejian.png')"/>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogSetPassword = false">{{ $t('navbar.modal.editPasswordModal.footerBtn.cancle') }}</el-button>
      <el-button type="primary" @click="setpassword">{{ $t('navbar.modal.editPasswordModal.footerBtn.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {setPassword} from "@/api/login";
import { exitApp } from '@/utils/auth'

export default {
  name: "ResetPwd",
  data() {
    return {
      isShowOld: true,
      isShowNew: true,
      isShowCon: true,
      dialogSetPassword: false,
      form: {
        oldPassword: "",
        password: "",
        pass1: ""
      },
      formLabelWidth: "150px",
    }
  },
  computed: {
    rules() {
      const validatePass = (rule, value, callback) => {
        if(value == '') {
          callback(new Error(this.$t('navbar.modal.editPasswordModal.content.formRules.confirmPassword1')));
        } else if (value !== this.form.password) {
          callback(new Error(this.$t('navbar.modal.editPasswordModal.content.formRules.confirmPassword2')));
        } else {
          callback();
        }
      };
      const rules = {
        oldPassword: [
          { required: true, message: this.$t('navbar.modal.editPasswordModal.content.formRules.originalPassword'), trigger: "blur" },
        ],
        password: [
          { required: true, message: this.$t('navbar.modal.editPasswordModal.content.formRules.newPassword'), trigger: "blur" },
        ],
        pass1: [
          {
            required: true,
            // message: this.$t('navbar.modal.editPasswordModal.content.formRules.confirmPassword'),
            trigger: ['blur','change'],
            validator: validatePass,
          },
        ],
      };
      return rules;
    },
  },
  watch: {
    "$i18n.locale": function () {
      this.$nextTick(() => {
        this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
      });
    }
  },
  methods: {
    init() {
      this.form = {
        oldPassword: "",
        password: "",
        pass1: "",
      };
      this.$nextTick(() => {
        this.dialogSetPassword = true;
      });
    },
    setpassword() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 数据检验通过进行修改
          setPassword(this.form).then(response => {
            this.$confirm(response.msg,this.$t('navbar.modal.editPasswordModal.tips.setPasswordTipsTitle'), {
              confirmButtonText: 'OK',
              type: 'success',
              closeOnClickModal: false,
              closeOnPressEscape:false,
              closeOnHashChange:false,
              showCancelButton: false,
              showClose: false
            }).then(res => {
              exitApp();
            })
          })
        }
      });
    },
    handleSetPasswordModalClose() {
      this.$refs.ruleForm.resetFields();
      this.isShowOld = true;
      this.isShowNew = true;
      this.isShowCon = true;
    },
  }
}
</script>

<style scoped>

</style>
