export default {
  // 登录页
  login: {
    title: 'Account login',
    logIn: 'Log in',
    account: 'Account',
    password: 'Password',
    accountTips: 'Please enter your login account!',
    passwordTips: 'Please enter your password!'
  },
  // 导航
  navbar: {
    dashboard: 'Front page',
    logOut: 'Sign out',
    editPassword: 'Change password',
    uploadClient: 'Upload client',
    downloadClient: 'Download client',
    process: {
      processMessage: '流程消息',
      viewMore: '查看更多',
    },
    modal: {
      uploadClientModal: {
        title: 'Upload client',
        content: {
          form: {
            clientProgram: 'Client program',
            productName: 'Product name'
          },
          formRules: {
            productName: 'Please enter product name'
          }
        },
        footerBtn: {
          cancle: 'Cancel',
          confirm: 'Confirm'
        },
        tips: {
          fileTips: 'Drag your files here, or click to upload',
          fileEmptyTips: 'Please select the file to upload first!',
          exceedTips: "The current limit is to select 1 file. Currently {count} files were selected."
        }
      },
      downloadClientModal: {
        title: 'Download client',
        content: {
          downloadFileSelection: 'Download file selection',
          placeholder: 'Please select',
          fileOptionsDel: 'Delete'
        },
        footerBtn: {
          download: 'Download'
        },
        tips: {
          fileEmptyTips: 'Please select a file to download!',
          delTipsTitle: 'Hint',
          delTipsContent: "This operation will delete the client. Do you want to continue?",
          cancleTips: "Deletion canceled",
          delTipsCancle: 'Cancel',
          delTipsConfirm: 'Confirm',
        }
      },
      editPasswordModal: {
        title: 'Personal password settings',
        content: {
          form: {
            originalPassword: 'Old password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password'
          },
          formRules: {
            originalPassword: 'Please enter the old password',
            newPassword: 'Please enter a new password',
            confirmPassword1: 'Please enter the confirmation password',
            confirmPassword2: 'Please keep the password consistent twice'
          }
        },
        footerBtn: {
          cancle: 'Cancel',
          confirm: 'Confirm'
        },
        tips: {
          fileTips: 'Drag your files here, or click to upload',
          fileEmptyTips: 'Please select the file to upload first!',
          setPasswordTipsTitle: "System hint"
        }
      }
    }
  },
  // 菜单
  pages: {
    // 元器件库
    componentsLib: {
      search: {
        placeholder: 'Please enter a rule name'
      },
      tableHeader: {
        entryName: "Rule name",
        entryIdentification: "Rule ID",
        checkType: "Review method",
        checkLeave: "Level",
        weldingType: "Censors",
        ruleValue: "Design Parameters",
        designatedArea: "Parameter Description",
        operate: "Operate",
      },
      btn: {
        treeMenuBtn: {
          edit: 'Edit',
          delete: 'Delete',
          addSubordinate: "Add subordinate",
          upgrade: 'Upgrade',
          copy: "Copy",
          history: "History",
        },
        tableBtn: {
          headerBtn: {
            advancedSearch: 'Advanced search',
            moveEntries: 'Move entry',
            addEntries: 'Add entry',
            batchDeletion: 'Batch deletion',
            importEntries: 'Import entries',
            exportEntries: 'Export items',
            exportAllEntries: 'Export all entries',
            exportCategoryEntries: 'Export category entries',
            exportRuleGroupEntries: 'Export rule group entries',
            exportCheckedEntries: 'Export checked entries'
          },
          operateBtn: {
            edit: 'Edit',
            copy: 'Copy',
            delete: 'Delete'
          }
        }
      },
      modal: {
        addRuleClassModal: {
          title: 'Add rule class',
          editTitle: 'Edit rule class',
          content: {
            form: {
              className: "Class name",
              version: "Version number",
              remark: 'Remark',
            },
            formRules: {
              className: "Please enter a class name",
              version: "Please enter the version number",
              roleRevise: "Please enter the rule version"
            },
            placeholder: {
              className: "Please enter a class name, no more than 50 characters",
              version: "Please enter the version number",
              remark: "Please enter a description of the rule, no more than 100 words",
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm',
          },
          tips: {
            roleNameEmptyTips: "Please fill in the rule information!",
            remarkTextTips: "You can enter {count} more characters",
            versionTips: "The new version number should be greater than the old version number"
          }
        },
        upgradeModal: {
          title: 'Please enter the upgraded version number',
          content: {
            form: {
              version: "Version number",
            },
            placeholder: {
              version: "Please enter version number",
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm',
          },
          tips: {
            versionTips1: "version number:",
            versionTips2: "You should enter a legal number greater than the current version number as the new version number",
            versionEmptyTips: "Version number cannot be empty",
          }
        },
        copyRuleModal: {
          title: 'Copy rule class',
          content: {
            form: {
              newClassName: "New class name",
              version: "Version number",
            },
            formRules: {
              newClassName: "Please enter a class name",
            },
            placeholder: {
              newClassName: "Please enter a class name, no more than 50 characters",
              version: "Version number",
            }
          },
          footerBtn: {
            confirm: 'Confirm',
          },
          tips: {
            versionTips1: "Rule name and version number cannot be empty",
          }
        },
        historicVersionModal: {
          title: "rule history version",
          historicalVersionsTitle: "Advanced search of historical versions",
        },
        treeMenuEditModal: {
          title: 'Add/modify rule group',
          content: {
            form: {
              roleName: 'Group name',
              remark: 'Remark'
            },
            formRules: {
              roleName: 'Please enter a group name'
            },
            placeholder: {
              roleName: 'Please enter a group name, no more than 50 characters',
              remark: 'Please enter a group description, no more than 100 words'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            roleNameEmptyTips: 'Please fill in the rule information!',
            remarkTextTips: 'You can enter {count} words'
          }
        },
        advancedSearchModal: {
          title: 'Advanced Search',
          content: {
            addSearchCriteria: 'Add search conditions',
            valuePlaceholder: 'Please enter',
            searchConditionsOption1: 'Rule name',
            searchConditionsOption2: 'Rule ID',
            searchConditionsOption3: 'Review method',
            searchConditionsOption4: 'Level',
            searchConditionsOption5: 'Censors',
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            searchConditionErrTips: 'Please fill in the complete search criteria!',
            searchExistsTips: 'In the advanced search pop-up window, the search conditions have been entered!'
          }
        },
        moveEntriesModal: {
          title: 'Please select a location to move to',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            emptyCategoryTips: 'Please select a category to move to!',
            sameCategoryTips: 'The category to be moved is the same as the current category, no need to move',
          }
        },
        addEntriesModal: {
          title: 'Rule add/edit',
          content: {
            form: {
              entryName: 'Rule name',
              entryIdentification: 'Rule ID',
              ruleGroup: "Rule group",
              checkType: 'Review method',
              checkLeave: 'Level',
              weldingType: 'Censors',
              ruleValue: 'Design Parameters',
              designatedArea: 'Parameter Description',
              remark: 'Rule description'
            },
            formRules: {
              entryName: 'Please enter a rule name',
              entryIdentification: 'Please enter the rule ID',
              checkType: 'Please select review method',
              checkLeave: 'Please select a level',
              weldingType: 'Please select a reviewer'
            },
            placeholder: {
              entryName: 'Please enter a rule name',
              entryIdentification: 'Please enter the rule ID',
              checkType: 'Please select review method',
              checkLeave: 'Please select a level',
              weldingType: 'Please select a reviewer',
              ruleValue: 'Please enter design parameters',
              designatedArea: 'Please enter parameter description',
              remark: 'Please enter a description of the rule. It cannot exceed 1,000 words.'
            },
            btn: {
              selectRuleImage: 'Select rule image',
              clickToUpload: 'Click to upload',
              selectRuleDocument: 'Select rule document'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            ruleValueTips: 'You can only enter: letters, numbers, English commas, English semicolons, English colons, English dots, dashes, and underlines. You can enter up to 1,000 words.',
            designatedAreaTips: 'You can enter up to 1,000 words',
            selectRuleImageTips: 'Only jpg/png/gif files can be uploaded, and only 1 picture is allowed to be selected.',
            remarkTextTips: 'You can also enter {count} words',
            entryIdentificationTips: "Required in automatic review mode, optional in manual review mode",
            notAllowedToUploadTips: 'Not allowed to upload',
            perfectInformationTips: "Please complete the information first",
            selLastNodeTips: "Please select a last-level node first"
          }
        },
        selectRuleDocumentModal: {
          title: 'Select files to upload',
          content: {
            searchPlaceholder: 'Please enter file name',
            tableHeader: {
              fileName: "File name",
              fileSize: "File size",
              userName: "Uploader",
              updateTime: "Upload time",
            },
            btn: {
              uploadFile: 'Upload files'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            emptyFileTips: 'Please select a file first'
          }
        },
        uploadFileModal: {
          title: 'Select files to upload',
          titlePreview: "Preview",
          content: {
            btn: {
              uploadFile: 'Click to select file'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm upload'
          },
          tips: {
            limitFileNumTips: 'Up to 10 files can be uploaded at a time',
            notAllowedToUploadTips: 'Not allowed to upload',
            emptyFileTips: 'Please select a file first'
          }
        },
        batchDelModal: {
          title: 'Hint',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            delTips: 'The checked rule entries will be deleted. Do you want to continue?',
            cancleTips: 'Undelete'
          }
        },
        importEntriesModal: {
          title: 'Import entries',
          content: {
            btn: {
              downloadTemplate: 'Download \'Knowledge base import data template\' now'
            }
          },
          footerBtn: {
            confirm: 'Confirm upload'
          },
          tips: {
            emptyFileTips: 'Please select file',
            uploadTips: 'Drag files here, or click Upload',
            stepsTips: 'Steps:',
            stepsTips1: 'Download \'Knowledge base import data template\'',
            stepsTips2: 'Open the download form and enter or paste the corresponding field information into this form. To enConfirm that the pasted information is imported effectively, please use plain text or numbers.',
            stepsTips3: 'After entering the information, click the \'click Upload\' button and select the excel document',
            stepsTips4: 'Click \'Confirm upload\''
          }
        },
        delRuleModal: {
          title: 'Hint',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            delTips: 'This rule entry will be deleted, do you want to continue?',
            cancleTips: 'Undelete'
          }
        }
      },
      tips: {
        emptyMoveRuleEntryTips: 'Please select the rule entry you want to move first',
        emptyDelRuleEntryTips: 'Please select the rule entry to be deleted first',
        perfectInformationTips: 'Please complete the information first',
        emptyCheckItemsTips: 'Please select the exported items first!',
        emptyRuleGroupTips: "Please select a rule group first!",
        ruleDescriptionTips: "Description："
      },
      tabs: {
        ruleDescription: 'Rule description',
        rulesDocumentation: 'Rules document'
      }
    },
    // 我的自选库
    mycollection: {
      tableHeader: {
        value: "Value",
        createTime: "Creation time",
        remark: "Remark",
        operate: "Operate",
      },
      btn: {
        tableBtn: {
          headerBtn: {
            addNewConfigurationItems: 'Add new configuration item'
          },
          operateBtn: {
            edit: 'Revise',
            delete: 'Delete'
          }
        }
      },
      modal: {
        updateNewConfigurationItemsModal: {
          addTitle: 'Add new configuration item',
          editTitle: 'Modify maintenance items',
          content: {
            form: {
              configuration: 'Configuration items',
              inputValue: 'Input value',
              remark: 'Remark'
            },
            placeholder: {
              configuration: 'Configuration items',
              inputValue: 'Input value',
              remark: 'Remark'
            },
            options: {
              configurationitemsOption1: 'Censors',
              configurationitemsOption2: 'Review method',
              configurationitemsOption3: 'Level',
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            perfectInformationTips: 'Please complete the information first',
            remarkTextTips: 'You can also enter {count} characters',
            unmodifiedTips: 'If it has not been modified, do not confirm it.'
          }
        }
      },
      tabs: {
        censors: 'Censors',
        censorshipMethod: 'Review method',
        censorshipLevel: 'Censorship level'
      }
    },
    // 常用库
    usedMycollection: {
      search: {
        placeholder: 'Please enter file name'
      },
      tableHeader: {
        fileName: "File name",
        fileSize: "File size",
        userName: "Uploader",
        updateTime: "Upload time",
        operate: "Operate",
      },
      btn: {
        tableBtn: {
          headerBtn: {
            uploadFile: 'Upload files'
          },
          operateBtn: {
            download: 'Download',
            delete: 'Delete'
          }
        }
      },
      modal: {
        uploadFileModal: {
          title: 'Select files to upload',
          content: {
            btn: {
              uploadFile: 'Click to select file'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm upload'
          },
          tips: {
            emptyFileTips: 'Please select the file to upload first',
            limitFileNumTips: 'Up to 10 files can be uploaded at a time'
          }
        },
        repeatFileUploadModal: {
          title: 'Hint',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm',
          },
          tips: {
            delTips: "The system has detected that the file {fileName} already exists. Do you want to update it?",
            cancleTips: "Update canceled"
          },
        },
      },
      tabs: {
        censors: 'Censors',
        censorshipMethod: 'Review method',
        censorshipLevel: 'Censorship level'
      }
    },
    // 评价及反馈
    ErrorFeedback: {
      search: {
        rolePlaceholder: 'Please choose',
        keywordPlaceholder: "Name",
        accountLikePlaceholder: "Login account",
        staffNoLikePlaceholder: 'Job number',
        emailLikePlaceholder: 'Email',
        addressLikePlaceholder: 'OfficeAddress',
        options: {
          roleOption1: "All users",
          roleOption2: "General user",
          roleOption3: "System administrator",
        }
      },
      tableHeader: {
        index: "Serial number",
        userName: "Name",
        account: "Login account",
        userType: "Account type",
        staffNo: "Job number",
        sex: "Gender",
        postName: "Post",
        secretLevelName: "secretLevel",
        deptName: "Main department",
        position: "Job title",
        officeAddress: "OfficeAddress",
        phone: "Phone",
        email: "Email",
        idCard: "IdCard",
        enable: "Whether to enable",
        operate: "Operate",
      },
      tableCol: {
        sex: {
          value1: 'man',
          value2: 'woman',
          value3: 'unknown'
        }
      },
      btn: {
        treeMenuBtn: {
          addSubordinate: 'Create new subordinate',
          addLevel: 'Create new level',
          edit: 'Edit',
          delete: 'Delete'
        },
        tableBtn: {
          headerBtn: {
            changeDepartment: 'Change department',
            addEmployee: 'Add employee',
            more: 'More',
            importEmployeeInformation: 'Import employee information',
            exportEmployeeInformation: 'Export employee information'
          },
          operateBtn: {
            edit: 'Edit',
            role: 'Role',
            resetPassword: 'Reset Password',
            loginIsProhibited: 'Login prohibited',
            allowLogin: 'Allow login',
            deactivate: 'Deactivate',
            enable: 'Enable'
          }
        }
      },
      modal: {
        updateDepartmentModal: {
          title: 'Department',
          content: {
            form: {
              deptName: 'Department name',
              superiorDepartment: 'Higher office',
              leadingOfficial: 'Department head',
              departmentNumber: 'Department Number'
            },
            formRules: {
              deptName: 'Please enter department name',
              deptName1: "2 to 20 characters in length",
              superiorDepartment: "Please select the parent department",
              leadingOfficial: 'Please add department head'
            },
            placeholder: {
              roleName: 'Please enter a group name, no more than 50 characters',
              remark: 'Please enter a group description, no more than 100 words',
              superiorDepartment: "Please select the parent department",
              leadingOfficial: 'Please add department head'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            leadingOfficialTips: 'The department head is the department manager, and the data system, sales reports, etc. can be set up according to the statistics of the department head.',
            emptyLeadingOfficialTips: 'Please select the department head'
          }
        },
        changeDepartmentModal: {
          title: 'Mobile department',
          content: {
            placeholder: 'Department'
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          }
        },
        updateEmployeeModal: {
          title: 'Staff',
          content: {
            accountInformatioTitle: 'Account information',
            organizationalRelationshipsTitle: 'Organizational relationships',
            personalInformationTitle: 'Personal information',
            form: {
              account: 'Login account',
              userName: 'Name',
              userType: 'Account type',
              staffNo: 'Job number',
              sex: 'Gender',
              post: "Post",
              secretLevel: "SecretLevel",
              mainDepartment: 'Main department',
              officeAddress: "OfficeAddress",
              phone: "Phone",
              idCard: "IdCard",
              affiliatedDepartments: 'Affiliated departments',
              position: 'Job title',
              entryTime: 'Joining date',
              birthday: 'Birthday',
              email: 'Email'
            },
            formRules: {
              account: 'Please enter your login account',
              userName: 'Please enter employee name',
              email: "Please enter a valid email"
            },
            placeholder: {
              account: "The login account is the employee's unique identification in the enterprise, please do not repeat it.",
              userName: 'Please enter your name',
              staffNo: 'Please enter your job number',
              post: "Please select a position",
              secretLevel: "Please enter the secretLevel",
              officeAddress: "Please enter your officeAddress",
              phone: "Please enter phone",
              idCard: "Please enter idCard",
              position: 'Please enter job title',
              entryTime: 'Please select your joining date',
              birthday: 'Select date',
              email: 'Please enter your email'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Keep',
            saveAndContinueAdding: 'Save and continue adding'
          },
          tips: {
            accountCheckTips: 'Account length must be 3-20 digits',
            mainDepartmentTips: 'Please select your primary department first',
            phoneErrorTips: "Please enter a legal mobile phone number",
            emailErrorTips: "Please enter a valid email address",
            idCardErrorTips: "Please enter a valid ID number",
          }
        },
        importEmployeeInformationTipsModal: {
          title: 'Hint',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: 'If no department is selected, the default is no department personnel.'
        },
        importEmployeeInformationModal: {
          title: 'Import employee information',
          content: {
            btn: {
              downloadTemplate: 'Download \'User Batch Import Template\' now'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            updateTips: "Note: Please check the fields you want to import (all fields are imported by default)",
            uploadTips: 'Drag files here, or click Upload',
            stepsTips: 'Steps:',
            stepsTips1: 'Download \'User Batch Import Template\'',
            stepsTips2: 'Open the download form and enter or paste the corresponding field information into this form. To enConfirm that the pasted information is imported effectively, please use plain text or numbers.',
            stepsTips3: 'After entering the information, click the \'click Upload\' button and select the excel document',
            stepsTips4: 'Click \'Confirm\'',
            uploadErrorTips: "Upload information prompt",
            uploadAccountError1Tips: "The login account in row {row} and column {col} cannot be empty, please check",
            uploadAccountError2Tips: "The login account in row {row} and column {col} must be 3-20 digits, please check",
            uploadAccountError3Tips: "The login account in row {row} and column {col} cannot be in Chinese, please check",
            uploadUserNameErrorTips: "The name in row {row} and column {col} cannot be empty, please check",
            uploadGenderErrorTips: "The gender format at row {row} and column {col} is wrong, please check",
            uploadPhoneErrorTips: "The phone format at row {row} and column {col} is wrong, please check",
            uploadEmailErrorTips: "The email format at row {row} and column {col} is incorrect, please check",
            uploadIDcardErrorTips: "The format of the idCard in row {row} and column {col} is wrong, please check",
            uploadBirthdayErrorTips: "The birth date format in the column {row} and {col} is incorrect, please check",
          }
        },
        roleModal: {
          title: 'Set up roles',
          content: {
            roleTitle: 'Role',
            haveTitle: 'Have',
            placeholder: 'Enter search content'
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          }
        },
        resetPasswordModal: {
          title: 'Reset Password',
          content: {
            placeholder: 'Password after reset'
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            emptyTip: "Reset password cannot be empty",
          }
        },
        loginBinOrPassTipsModal: {
          title: 'Hint',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            binTips: 'Are you Confirm you want to ban this account from logging in?',
            passTips: 'Are you Confirm you want to allow this account to log in?'
          }
        },
        accountStopOrStartTipsModal: {
          title: 'Hint',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            stopTips: 'Are you Confirm you want to disable this account?',
            startTips: 'Are you Confirm you want to activate this account?'
          }
        }
      },
      tips: {
        allDepartment: 'All departments',
        noDepartment: 'There is no department yet, please click to add',
        sameDepartmentTips: 'Subsidiary departments cannot be the same as primary departments'
      }
    },
    // 统计
    statistical: {
      search: {
        placeholder: 'Role Name',
        searchBtn: 'Inquire'
      },
      tableHeader: {
        index: "Serial number",
        userName: "Role name",
        remark: "Introduction",
        roleType: "Type",
        operate: "Operate",
      },
      tableCol: {
        roleType: {
          value1: "Business roles",
          value2: "Manage roles",
          value3: "System built-in roles",
        }
      },
      btn: {
        tableBtn: {
          headerBtn: {
            add: 'Add to'
          },
          operateBtn: {
            check: 'Check',
            permissions: 'Permissions',
            functionButtons: 'Function buttons',
            delete: 'Delete',
            selectPeople: 'Select people'
          }
        }
      },
      modal: {
        addAndCheckModal: {
          title: 'Role',
          content: {
            form: {
              roleName: 'Role Name',
              remark: 'Introduction',
              sortNo: 'Related personnel'
            },
            formRules: {
              roleName: 'Please enter a role name',
              roleName1: '2 to 15 characters in length'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          }
        },
        permissionsModal: {
          title: 'Assign menu to {name}',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: {
            emptyTips: "If the user cannot enter the system without a menu, please keep at least one menu",
            setPasswordTipsTitle: "System hint"
          }
        },
        functionButtonsModal: {
          title: 'Assign button permissions to {name}',
          content: {
            btn: {
              allSelect: 'Select all',
              cancleAllSelect: 'Deselect all'
            }
          },
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          }
        },
        delTipsModal: {
          title: 'Hint',
          footerBtn: {
            cancle: 'Cancel',
            confirm: 'Confirm'
          },
          tips: 'This operation will be permanently deleted. Do you want to continue?'
        },
        selectPeopleModal: {
          title: 'Select personnel for skilled workers',
          content: {
            searchPlaceholder: 'Please enter employee name',
            allSelect: 'Select all'
          },
          footerBtn: {
            confirm: 'Keep'
          },
          tips: 'This operation will be permanently deleted. Do you want to continue?'
        }
      }
    },
    // 元器件管理设置
    partsSetting: {
      search: {
        symbolPlaceholder: 'Please select drawing number',
        versionvaluePlaceholder: 'Please select a version number',
        cishuvaluePlaceholder: 'Please select the number of times'
      },
      echarts: {
        title1: 'Rule check quality statistics',
        title2: 'Rule check frequency statistics',
        toolbox: {
          dataView: 'Data View',
          magicType1: 'Switch to line chart',
          magicType2: 'Switch to bar chart',
          restore: 'Restore',
          saveAsImage: 'Save as image',
          close: 'close',
          refresh: 'refresh',
        }
      },
      tips: {
        normal: 'Normal',
        notChecked: 'Not checked',
        error: 'Mistaken',
      }
    },
    // 回收站
    recycleBin: {},
    // 目录类型管理
    drectoryManage: {},
    // 数据手册
    datasheet: {
      search: {
        placeholder: "请按数据手册名称和制造商名称进行检索",
        treePlaceholder: "请输入搜索内容"
      },
      tableHeader: {
        categoryName: "分类名称",
        manualName: "数据手册",
        manufacturersName: "制造商",
        status: "状态",
        createTime: "上传时间",
        userName: "上传人",
        description: "说明",
        remark: "备注",
        operate: "操作",
      },
      btn: {
        treeBtn: {
          AddFirstLevelClassification: "新增一级分类",
          viewAll: "查看全部",
        },
        treeMenuBtn: {
          edit: "修改分类",
          delete: "删除分类",
          addSubordinate: "添加分类",
        },
        tableBtn: {
          headerBtn: {
            updateAll: "更新全部手册的制造商",
            upload: "上传数据手册",
            move: "移动手册分类",
            recycleBin: "回收站",
          },
          operateBtn: {
            update: '更新制造商',
            preview: '预览',
            edit: '编辑',
            historicVersion: '历史版本',
            delete: '删除'
          }
        }
      },
      modal: {
        updateCategory: {
          title: '添加分类',
          editTitle: '编辑分类',
          content: {
            form: {
              categoryName: "分类名称",
            },
            placeholder: {
              categoryName: "请输入分类名称",
            }
          },
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
          tips: {
            categoryNameEmptyTips: "分类名称不能为空！",
          }
        },
        updateManual: {
          title: '上传数据手册',
          editTitle: '编辑',
          content: {
            form: {
              manufacturer: "制造商",
              classification: "分类",
              status: "状态",
              description: "说明",
              manualName: "名称",
              inputVersion: "录入版本号",
              description: "描述",
              changeDescription: "更改说明",
              remark: "备注",
            },
            placeholder: {
              manufacturer: "请选择制造商",
              classification: "请选择分类",
              description: "请输入说明",
            },
            options: {
              statusOption1: '正式',
              statusOption2: '临时',
            }
          },
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
          tips: {
            fileEmptyTips: "请先选择要上传的数据手册",
            classificationEmptyTips: "请选择分类！",
            addManufacturerTips: "点击添加制造商",
            uploadTips: "上传数据手册",
            fileSizeLimitTips: "单个文件大小不超过200M",
            fileTotalSizeLimitTips: "文件总大小超过200M，请重新选择上传文件",
            fileNumberLimitTips: "当前限制选择 100 个文件，本次选择了 { length1 } 个文件，共选择了 { length2 } 个文件",
            removefileTips: "确定移除 { name }？",
            fileWithTheSameNameTips: "不能选择同名文件",
          }
        },
        duplicateFiles: {
          title: '更新',
          tableHeader: {
            manualName: "数据手册",
            manufacturersName: "制造商",
            status: "状态",
            createTime: "上传时间",
            description: "说明",
          },
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
        },
        duplicateFilesPrompt: {
          title: '提示',
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
          tips: {
            delTips: "文件 { tipInfo } 已存在  是否更新",
            cancleTips: "已取消更新"
          },
        },
        addManufacturer: {
          title: '制造商',
          content: {
            form: {
              name: "公司名称",
              abbreviation: "公司简称",
              differentCountries: "地址",
              website: "网址",
              phone: "联系方式",
              fax: "传真",
              email: "邮箱",
              oldName: "历史曾用名",
              intro: "公司简介",
              remark: "备注",
              logo: "Logo",
            },
            formRules: {
              name: "请输入公司名称",
              abbreviation: "请输入公司简称",
              email: "长度在 7 到 200 个字符",
            },
          },
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
          tips: {
            logoTips: "只能上传jpg/png文件",
            websiteTips: "请输入合法网址",
            phoneTips: "请输入合法手机号",
            faxTips: "传真格式不符合规范",
            emailTips: "请输入合法邮箱",
          }
        },
        moveClassificationManual: {
          title: "移动分类手册",
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
          tips: {
            emptyClassificationTips: "请选择目标分类！",
          }
        },
        recycleBinModal: {
          title: '回收站',
          search: {
            placeholder: "请按数据手册名称和制造商名称进行检索",
          },
          content: {
            tableHeader: {
              categoryName: "分类",
              manualName: "数据手册",
              manufacturersName: "制造商",
              status: "状态",
              userName: "上传人",
              createTime: "上传时间",
              updateTime: "删除时间",
              operate: "操作",
            },
            btn: {
              tableBtn: {
                headerBtn: {
                  delete: "删除 ",
                },
                operateBtn: {
                  historyRecord: '历史记录',
                  recover: '恢复',
                  preview: '预览',
                }
              }
            },
          },
          tips: {
            manualEmptyTips: "请先选择数据手册!",
          }
        },
        renameModal: {
          content: {
            placeholder: {
              name: "请输入文件名称",
            },
            btn: {
              rename: "重命名",
              revertToHistory: "恢复成历史记录",
            },
          },
          footerBtn: {
            confirm: '确定',
          },
          tips: {
            nameEmptyTips: "名称不能为空！",
          }
        },
        historicVersion: {
          title: '历史版本',
          content: {
            tableHeader: {
              manualName: "数据手册",
              versionNumber: "版本号",
              status: "状态",
              userName: "上传人",
              createTime: "上传时间",
              operate: "操作",
            },
            btn: {
              tableBtn: {
                operateBtn: {
                  preview: '预览',
                }
              }
            },
          },
        },
        deletePrompt: {
          title: '提示',
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
          tips: {
            delTips: "是否确定删除该手册?",
            cancleTips: "已取消删除"
          },
        },
        updateCanclePrompt: {
          title: '提示',
          footerBtn: {
            cancle: '取消',
            confirm: '确定',
          },
          tips: {
            delTips: "是否确定取消?",
            cancleTips: "已取消删除"
          },
        },
      },
      tips: {
        manualEmptyTips: "该手册不存在！",
      },
    },
    // 待办审批
    waitDo: {
    },
    // 流程设计
    processDesign: {},
    // 运行中流程
    runningProcess: {},
    // 已完成审批
    yetDo: {},
    // 我提交的
    mySubmit: {},
    // 类目管理
    categoryManage: {},
    // 属性模板管理
    attributeTemp: {},
    // 字段配置
    fieldRechecking: {},
    // 类目示意图
    categoryPicture: {},
    // 审批模板
    approveTemplate: {},
    // 编码规则
    codePage: {},
    // 制造商
    manufacturerList: {},
    // 供应商
    supplierList: {},
    // Capture符号库
    symbolFormList: {},
    // Concept符号库
    symbolicLibrary: {},
    // Allegro封装库
    packageFileUpload: {},
    // Altium符号库
    altiumsymbolList: {},
    // Altium封装库
    altiumEncapsulationList: {},
    // 三维库
    threeDimensionalLib: {},
    // 设计审查
    dataReview: {},
    // 审查配置
    reviewSet: {},
    // BOM自检
    bomSelfCheck: {},
    // BOM互检
    bomMutualCheck: {},
    // BOM编辑
    bomEdit: {},
    // 产品设计管理
    ProductManage: {},
    // 元器件统计
    ComponentStatistics: {},
    // 板卡BOM
    bomManagement: {},
    // BOM模板配置
    bomTemplateConfig: {},
    // 质量信息库
    info: {},
    // 统计分析
    stat: {},
    // 全部消息
    allMessage: {},
    // 未读消息
    unreadMessage: {},
    // 已读消息
    readMessage: {},
    // 员工管理
    employeesManage: {},
    // 角色管理
    webRolePermiss: {},
    // 系统设置
    systemSetting: {},
    // 集成管理
    integrationManagement: {},
    // BOM比较
    bomCompare: {},
    // 操作日志
    operateLogs: {},
    // 登录日志
    loginLogs: {},
    // 模块电路-文件库
    filesList: {},
    // 模块电路-Altium模型库文件
    altiumFiles: {},
    404: {
      content: {
        text1: 'Please check whether the URL you entered is correct, please click the button below to return',
        text2: 'Home page or send a bug report',
        text3: "暂无进入该页面的权限或该页面不存在！",
        backHomeBtn: 'Return to homepage'
      }
    }
  }
}
